import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  unstable_useFuture as useFuture,
} from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { AuthContextProvider } from './context/AuthContext';
import { ConfigProvider } from './context/ConfigContext';
import { DonationsProvider } from './context/DonationsContext';
import { Toaster } from 'react-hot-toast';
import App from './App.tsx';
import GamePage from './GamePage.tsx';
import DonatePage from './DonatePage.tsx';
import UserPage from './UserPage.tsx';
import LegalPage from './LegalPage.tsx';
import DynamicTitle from './components/DynamicTitle.tsx';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthContextProvider>
      <ConfigProvider>
        <ThemeProvider>
          <DonationsProvider>
            <Router
              future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
              }}
            >
              <DynamicTitle />
              <Suspense
                fallback={
                  <div className="flex justify-center items-center h-screen">
                    Carregant...
                  </div>
                }
              >
                <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-300">
                  <div className="bg-transition">
                    <Routes>
                      <Route path="/" element={<App />} />
                      <Route path="/:path" element={<GamePage />} />
                      <Route path="/donar" element={<DonatePage />} />
                      <Route path="/usuari" element={<UserPage />} />
                      <Route path="/legal" element={<LegalPage />} />
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </div>
                </div>
              </Suspense>
            </Router>
            <Toaster position="top-right" />
          </DonationsProvider>
        </ThemeProvider>
      </ConfigProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
