import React from 'react';

// Definición de la interfaz para la información del juego
export interface GameInfo {
  id: number;
  title: string;
  description?: string;
  instructions?: string[];
  icon: React.ComponentType;
  path: string;
  category: string;
  theme: {
    gradient: {
      from: string;
      to: string;
    };
    pattern: {
      type: string;
      color: string;
      opacity: number;
      size: number;
      spacing: number;
    };
  };
  localStorageKey: string;
  customStatLabel: string;
  customStatLabelSingular: string;
}

// Definición de la interfaz para las etiquetas del juego
export interface GameTag {
  label: string;
  priority: number;
}

// Definición de la interfaz para las estadísticas del juego
export interface GameStat {
  id: number;
  gamesPlayed: number;
  maxRound: number;
  customStat: number;
  gameName: string;
}

// Definición de la interfaz para las categorías del juego
export interface GameCategory {
  label: string;
}

// Definición de la interfaz para un juego
export interface Game {
  info: GameInfo;
  getStats: () => GameStat;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
}

// Definir las categorías de juegos
export const GAME_CATEGORIES: GameCategory[] = [
  { label: 'Arcade' },
  { label: 'Puzles' },
  { label: 'Estratègia' },
  { label: 'Acció' },
  { label: 'Aventura' },
  { label: 'Esports' },
  { label: 'Multijugador' },
  { label: 'Infantils' },
  { label: 'Educatius' },
  { label: 'Retro' },
  { label: 'Curses' },
  { label: 'Habilitat' },
  { label: 'Simulació' },
];

// IDs de juegos nuevos y populares
export const NEW_GAME_IDS: number[] = [1];
let MOST_POPULAR_GAME_IDS: number[] = [2];

// Función para establecer los juegos más populares
export const setMostPopularGames = (ids: number[]): void => {
  MOST_POPULAR_GAME_IDS = ids;
};

// Función para verificar si un juego es nuevo
export const isNewGame = (gameId: number): boolean => {
  return NEW_GAME_IDS.includes(gameId);
};

// Función para verificar si un juego es popular
export const isMostPopular = (gameId: number): boolean => {
  return MOST_POPULAR_GAME_IDS.includes(gameId);
};

// Función para obtener las etiquetas de un juego
export const getGameTags = (
  gameId: number,
  gameStats: GameStat[],
  mostPlayedGameId: number,
  bestRoundGameId: number
): GameTag[] => {
  const tags: GameTag[] = [];
  const isNew = isNewGame(gameId);
  const isMostPlayed = gameId === mostPlayedGameId;
  const isPopular = isMostPopular(gameId);
  const isBestRound = gameId === bestRoundGameId;

  if (isNew) {
    tags.push({ label: 'Nou', priority: 4 });
  }
  if (isPopular) {
    tags.push({ label: 'Més Popular', priority: 3 });
  }
  if (isMostPlayed) {
    tags.push({ label: 'Més Jugat', priority: 2 });
  }
  if (isBestRound) {
    tags.push({ label: 'Millor Ronda', priority: 1 });
  }

  // Ordenar por prioridad en orden descendente
  tags.sort((a, b) => b.priority - a.priority);

  // Devolver solo las dos primeras etiquetas
  return tags.slice(0, 2);
};

// Función para obtener el juego favorito
export const getFavoriteGame = (
  games: Game[],
  gameStats: { id: number; gamesPlayed: number }[]
): { title: string; id: number } => {
  // Buscar el más jugado primero
  const mostPlayed = gameStats.reduce(
    (prev, current) =>
      current.gamesPlayed > prev.gamesPlayed ? current : prev,
    { id: 0, gamesPlayed: 0 }
  );

  if (mostPlayed.gamesPlayed > 0) {
    const game = games.find((g) => g.info.id === mostPlayed.id);
    if (game) {
      return { title: game.info.title, id: game.info.id };
    }
  }

  // Si hay juegos nuevos, mostrar el primero
  if (NEW_GAME_IDS.length > 0) {
    const newGame = games.find((game) => NEW_GAME_IDS.includes(game.info.id));
    if (newGame) {
      return { title: `${newGame.info.title}`, id: newGame.info.id };
    }
  }

  // Si no hay juego más jugado ni nuevos, mostrar el primer juego disponible
  if (games.length > 0) {
    return { title: games[0].info.title, id: games[0].info.id };
  }

  // Si no hay ningún juego, devolver valor por defecto
  return { title: '-', id: 0 };
};

// Función para ordenar los juegos
export const sortGames = (
  games: Game[],
  gameStats: GameStat[],
  mostPlayedGameId: number,
  bestRoundGameId: number
): Game[] => {
  return [...games].sort((a, b) => {
    const aPlayed =
      gameStats.find((gs) => gs.id === a.info.id)?.gamesPlayed || 0;
    const bPlayed =
      gameStats.find((gs) => gs.id === b.info.id)?.gamesPlayed || 0;
    const aIsNew = isNewGame(a.info.id);
    const bIsNew = isNewGame(b.info.id);
    const aIsMostPlayed = a.info.id === mostPlayedGameId;
    const bIsMostPlayed = b.info.id === mostPlayedGameId;
    const aIsPopular = isMostPopular(a.info.id);
    const bIsPopular = isMostPopular(b.info.id);
    const aIsBestRound = a.info.id === bestRoundGameId;
    const bIsBestRound = b.info.id === bestRoundGameId;
    const aIsGameOne = a.info.id === 1;
    const bIsGameOne = b.info.id === 1;

    // Nuevos juegos primero
    if (aIsNew && !bIsNew) return -1;
    if (!aIsNew && bIsNew) return 1;

    // Luego por más popular
    if (aIsPopular && !bIsPopular) return -1;
    if (!aIsPopular && bIsPopular) return 1;

    // Luego por más jugados (orden descendente)
    if (bPlayed > aPlayed) return 1;
    if (aPlayed > bPlayed) return -1;

    // Luego por mejor ronda (orden descendente)
    if (aIsBestRound && !bIsBestRound) return -1;
    if (!aIsBestRound && bIsBestRound) return 1;

    // Si ninguno es nuevo ni el más jugado ni el de mejor ronda, priorizar juego 1 si se han jugado menos de 10 veces
    if (aPlayed < 10 && bPlayed < 10) {
      if (aIsGameOne && !bIsGameOne) return -1;
      if (!aIsGameOne && bIsGameOne) return 1;
    }

    // Juego 1 siempre al final
    if (aIsGameOne && !bIsGameOne) return 1;
    if (!aIsGameOne && bIsGameOne) return -1;

    // Finalmente, ordenar por ID
    return a.info.id - b.info.id;
  });
};

// Función para obtener la categoría de un juego
export const getGameCategory = (game: Game): string | undefined => {
  return game.info.category;
};

// Función para obtener juegos por categoría
export const getGamesByCategory = (games: Game[], category: string): Game[] => {
  return games.filter((game) => game.info.category === category);
};
