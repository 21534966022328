import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';

interface BaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onClose,
  children,
  className = '',
  maxWidth = 'lg',
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [modalYOffset, setModalYOffset] = useState(0);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  // Definir clases de ancho máximo responsivas
  const maxWidthClasses: { [key in BaseModalProps['maxWidth']]: string } = {
    sm: 'max-w-sm sm:max-w-lg lg:max-w-2xl',
    md: 'max-w-md sm:max-w-xl lg:max-w-3xl',
    lg: 'max-w-2xl sm:max-w-3xl lg:max-w-4xl',
    xl: 'max-w-4xl sm:max-w-5xl lg:max-w-6xl',
    full: 'max-w-full',
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleResize = () => {
      if (!isMobile) {
        return;
      }

      const visualViewportHeight = window.visualViewport?.height;
      const windowHeight = window.innerHeight;
      if (
        visualViewportHeight &&
        windowHeight &&
        Math.abs(visualViewportHeight - windowHeight) > 100
      ) {
        setIsKeyboardOpen(true);
        setModalYOffset(-200);
      } else {
        setIsKeyboardOpen(false);
        setModalYOffset(0);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
      window.addEventListener('resize', handleResize);
      handleResize();
      document.body.classList.add('modal-open');
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('modal-open');
    };
  }, [isOpen, onClose, isMobile]);

  // Animación para PC (completa)
  const desktopVariants = {
    hidden: { opacity: 0, scale: 0.8, y: 50 },
    visible: {
      opacity: 1,
      scale: 1,
      y: isKeyboardOpen ? -100 : 0,
      transition: { type: 'spring', damping: 25, stiffness: 300 },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: isKeyboardOpen ? -100 : 50,
      transition: { type: 'spring', damping: 25, stiffness: 300 },
    },
  };

  // Animación para móviles (simplificada)
  const mobileVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const modalContent = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]"
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <motion.div
            ref={modalRef}
            variants={isMobile ? mobileVariants : desktopVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={`bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full mx-4 ${maxWidthClasses[maxWidth]} max-h-screen overflow-hidden ${className}`}
            style={{ transform: `translateY(${modalYOffset}px)` }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(modalContent, document.body);
};

export default BaseModal;
