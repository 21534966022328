import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import BackgroundTexture from './components/BackgroundTexture';
import CookieConsent from './components/CookieConsent';
import titleUtils from './utils/titleUtils';

const LegalPage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { titleFontSize, isMobile } = titleUtils();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setIsLoaded(true), 100);
  }, []);

  return (
    <div className="relative z-0">
      <BackgroundTexture className="background-texture" zIndex={-1} />
      <div
        className={`min-h-screen grid grid-rows-[auto_1fr_auto]  transition-colors duration-300 ${
          isLoaded ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {/* Contenedor del contenido principal */}
        <div className="px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
          <div className="max-w-7xl mx-auto">
            <header
              className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-10 opacity-0'
              } relative`}
            >
              <Link to="/" className="group">
                <div className="icon-bg">
                  <ArrowLeft className="text-white w-1/2 h-1/2" />
                </div>
              </Link>
              <h1
                className={`font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 leading-tight absolute left-1/2 -translate-x-1/2 ${titleFontSize}`}
              >
                Legal
              </h1>
              <DarkModeToggle />
            </header>

            <div
              className={`bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              }`}
            >
              <div className="p-4 sm:p-6 relative">
                {/* Compromís amb la Política de Famílies de Google Play */}
                <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
                  Compromís amb la Política de Famílies de Google Play
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Ens comprometem a complir amb la{' '}
                  <strong>Política de Famílies de Google Play</strong> per
                  garantir un entorn segur i adequat per a totes les edats. Tot
                  el contingut està dissenyat per ser apte per a famílies, i
                  prenem mesures per protegir la privacitat i la seguretat dels
                  menors.
                </p>

                {/* Avís Legal */}
                <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
                  Avís Legal
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  <strong>
                    <strong>minijocs.cat</strong>
                  </strong>{' '}
                  és una plataforma de minijocs en línia. L'ús d'aquest lloc web
                  implica l'acceptació dels nostres termes i condicions. Ens
                  reservem el dret de modificar el contingut del lloc sense
                  previ avís.
                </p>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Tots els drets de propietat intel·lectual dels minijocs i
                  continguts d'aquest lloc web són propietat de{' '}
                  <strong>minijocs.cat</strong> o dels seus respectius
                  propietaris. No està permesa la reproducció, distribució o
                  modificació sense autorització.
                </p>

                {/* Política de Privacitat */}
                <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
                  Política de Privacitat
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Respectem la vostra privacitat i ens comprometem a protegir
                  les vostres dades personals. Recollim informació necessària
                  per millorar la vostra experiència, incloent:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600 dark:text-gray-300">
                  <li>
                    Informació de registre (nom d'usuari o correu electrònic)
                  </li>
                  <li>Dades de joc (puntuacions o nivells assolits)</li>
                  <li>Informació tècnica (adreça IP o tipus de navegador)</li>
                  <li>Dades d'anàlisi web mitjançant Google Analytics</li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  No compartim les vostres dades personals amb tercers sense el
                  vostre consentiment, excepte quan sigui requerit per llei.
                </p>

                {/* Google Analytics */}
                <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
                  Google Analytics
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Utilitzem Google Analytics per analitzar l'ús del nostre lloc
                  web. Google Analytics utilitza cookies per recopilar
                  informació sobre com interactuen els usuaris amb el lloc.
                  Aquesta informació inclou:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600 dark:text-gray-300">
                  <li>Pàgines visitades i temps de permanència</li>
                  <li>Tipus de dispositiu i navegador utilitzat</li>
                  <li>Ubicació (país i ciutat)</li>
                  <li>Interaccions amb elements de la pàgina</li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Aquesta informació s'utilitza únicament per millorar
                  l'experiència d'usuari i el contingut del lloc web. Les dades
                  són processades de forma anònima i agregada.
                </p>

                {/* Política de Cookies */}
                <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
                  Política de Cookies
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Utilitzem cookies per millorar la vostra experiència de
                  navegació. Les cookies són petits arxius de text que
                  s'emmagatzemen al vostre dispositiu quan visiteu el nostre
                  lloc web. Utilitzem els següents tipus de cookies:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600 dark:text-gray-300">
                  <li>
                    Cookies essencials: necessàries per al funcionament bàsic
                    del lloc web
                  </li>
                  <li>
                    Cookies de rendiment: per analitzar com s'utilitza el nostre
                    lloc web (incloent Google Analytics)
                  </li>
                  <li>
                    Cookies de funcionalitat: per recordar les vostres
                    preferències
                  </li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  En continuar utilitzant el nostre lloc, accepteu la nostra
                  política de cookies. Podeu gestionar les vostres preferències
                  de cookies a través de la configuració del vostre navegador.
                </p>

                {/* Condicions d'Ús */}
                <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
                  Condicions d'Ús
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  En utilitzar <strong>minijocs.cat</strong>, accepteu complir
                  amb les nostres condicions d'ús, que inclouen:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600 dark:text-gray-300">
                  <li>
                    No utilitzar el lloc web per a fins il·legals o no
                    autoritzats
                  </li>
                  <li>No intentar accedir a àrees restringides del lloc web</li>
                  <li>No interferir amb el funcionament normal del lloc web</li>
                  <li>
                    Respectar els drets d'altres usuaris i no assetjar-los
                  </li>
                </ul>
                <p className="mb-4 text-gray-600 dark:text-gray-300">
                  Ens reservem el dret de modificar aquests termes en qualsevol
                  moment. És responsabilitat de l'usuari revisar periòdicament
                  aquestes condicions.
                </p>

                {/* Separador */}
                <hr className="my-6 border-gray-300 dark:border-gray-700" />

                {/* Texto de Actualización */}
                <div className="text-right text-[10px] xs:text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                  Última actualització: Gener 2024
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <CookieConsent />
    </div>
  );
};

export default LegalPage;
