// DonationsContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useConfig } from './ConfigContext';
import {
  initialTopDonations,
  initialRecentDonations,
  INITIAL_DATA_VERSION,
} from '../data/initialDonations';

interface Donation {
  name: string;
  amount: number;
  date?: string;
}

interface DonationsContextType {
  topDonations: Donation[];
  recentDonations: Donation[];
  totalDonated: number | null;
  isLoading: boolean;
  isRefreshing: boolean;
  error: string | null;
  hasFullData: boolean;
  refreshDonations: () => Promise<void>;
  loadFullData: () => Promise<void>;
}

const DonationsContext = createContext<DonationsContextType | undefined>(
  undefined
);

export const DonationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { version, configIsLoaded } = useConfig();

  const [topDonations, setTopDonations] = useState<Donation[]>([]);
  const [recentDonations, setRecentDonations] = useState<Donation[]>([]);
  const [totalDonated, setTotalDonated] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasFullData, setHasFullData] = useState(false);

  // Lee donaciones y meta-datos de localStorage
  const loadCachedDonations = () => {
    try {
      const top = localStorage.getItem('topDonations');
      const recent = localStorage.getItem('recentDonations');
      const localVersionStr = localStorage.getItem('donationsVersion');
      const localHasFullData = localStorage.getItem('donationsHasFullData');

      const localVersion = localVersionStr ? parseInt(localVersionStr, 10) : 0;
      const loadedTop = top ? JSON.parse(top) : null;
      const loadedRecent = recent ? JSON.parse(recent) : null;
      const loadedHasFullData = localHasFullData === 'true';

      return {
        top: loadedTop,
        recent: loadedRecent,
        version: localVersion,
        fullData: loadedHasFullData,
      };
    } catch (err) {
      console.error('Error cargando donaciones desde localStorage', err);
      return { top: null, recent: null, version: 0, fullData: false };
    }
  };

  // Guarda datos en localStorage junto con la versión y si son datos completos
  const saveDonationsToCache = (
    topData: Donation[],
    recentData: Donation[],
    newVersion: number,
    isFull: boolean
  ) => {
    localStorage.setItem('topDonations', JSON.stringify(topData));
    localStorage.setItem('recentDonations', JSON.stringify(recentData));
    localStorage.setItem('donationsVersion', newVersion.toString());
    localStorage.setItem('donationsHasFullData', String(isFull));
    setHasFullData(isFull);
  };

  // Al montar el provider, inicializamos state con datos locales o iniciales
  useEffect(() => {
    const cached = loadCachedDonations();
    if (!cached.top || !cached.recent) {
      // Sin nada en cache => usar datos iniciales
      setTopDonations(initialTopDonations);
      setRecentDonations(initialRecentDonations);

      localStorage.setItem('donationsVersion', INITIAL_DATA_VERSION.toString());
      localStorage.setItem('topDonations', JSON.stringify(initialTopDonations));
      localStorage.setItem(
        'recentDonations',
        JSON.stringify(initialRecentDonations)
      );
      localStorage.setItem('donationsHasFullData', 'false');
    } else {
      // Si la versión coincide con la actual (o la que tenemos en local), simplemente los usamos
      // No importa si es la primera vez: mientras no haya cambio de versión, usamos lo que está en caché
      setTopDonations(cached.top);
      setRecentDonations(cached.recent);
      setHasFullData(cached.fullData); // restauramos si eran datos completos
    }
  }, []);

  // Petición al servidor (API) para traer las donaciones
  const fetchDonationsFromServer = useCallback(
    async (loadFull = false) => {
      try {
        setIsLoading(true);
        setError(null);

        const baseUrl = import.meta.env.VITE_APP_URL;
        const topUrl = `${baseUrl}/.netlify/functions/get-top-donations${
          loadFull ? '' : '?initial=1'
        }`;
        const recentUrl = `${baseUrl}/.netlify/functions/get-recent-donations${
          loadFull ? '' : '?initial=1'
        }`;

        const [respTop, respRecent] = await Promise.all([
          fetch(topUrl).then((r) => r.json()),
          fetch(recentUrl).then((r) => r.json()),
        ]);

        setTopDonations(respTop);
        setRecentDonations(respRecent);
        setHasFullData(loadFull);

        // Guardar en cache con la nueva versión
        saveDonationsToCache(
          respTop,
          respRecent,
          version?.number ?? INITIAL_DATA_VERSION,
          loadFull
        );
      } catch (err) {
        console.error('Error fetching donations:', err);
        setError('Error al cargar donaciones. Inténtalo de nuevo.');
      } finally {
        setIsLoading(false);
      }
    },
    [version]
  );

  // Efecto: si cambia la versión de config (y ya está cargada), vemos si hace falta actualizar
  useEffect(() => {
    if (!configIsLoaded || !version) return;

    const localVersionStr = localStorage.getItem('donationsVersion');
    const localVersion = localVersionStr
      ? parseInt(localVersionStr, 10)
      : INITIAL_DATA_VERSION;

    // Si la versión global cambió, pedimos datos
    if (version.number !== localVersion) {
      // Caso especial: si se mantiene en INITIAL_DATA_VERSION, no forzamos fetch la primera vez
      // Pero si no es la primera vez, hacemos fetch
      if (
        !(
          localVersion === INITIAL_DATA_VERSION &&
          version.number === INITIAL_DATA_VERSION
        )
      ) {
        fetchDonationsFromServer(false);
      }
    }
  }, [configIsLoaded, version, fetchDonationsFromServer]);

  // Refresco manual
  const refreshDonations = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await fetchDonationsFromServer(hasFullData);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchDonationsFromServer, hasFullData]);

  // Carga completa
  const loadFullData = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await fetchDonationsFromServer(true);
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchDonationsFromServer]);

  return (
    <DonationsContext.Provider
      value={{
        topDonations,
        recentDonations,
        totalDonated,
        isLoading,
        isRefreshing,
        error,
        hasFullData,
        refreshDonations,
        loadFullData,
      }}
    >
      {children}
    </DonationsContext.Provider>
  );
};

export const useDonations = () => {
  const ctx = useContext(DonationsContext);
  if (!ctx) {
    throw new Error('useDonations must be used within a DonationsProvider');
  }
  return ctx;
};
