// utils/titleUtils.ts
import { useState, useEffect } from 'react';

const titleUtils = () => {
  const [screenSize, setScreenSize] = useState<
    'mobile' | 'smallTablet' | 'tablet' | 'intermediate' | 'desktop'
  >(
    window.innerWidth <= 480
      ? 'mobile'
      : window.innerWidth <= 768
      ? 'smallTablet'
      : window.innerWidth <= 1024
      ? 'tablet'
      : window.innerWidth <= 1280
      ? 'intermediate'
      : 'desktop'
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setScreenSize('mobile');
      } else if (window.innerWidth <= 768) {
        setScreenSize('smallTablet');
      } else if (window.innerWidth <= 1024) {
        setScreenSize('tablet');
      } else if (window.innerWidth <= 1280) {
        setScreenSize('intermediate');
      } else {
        setScreenSize('desktop');
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const titleFontSize =
    screenSize === 'mobile'
      ? 'text-4xl'
      : screenSize === 'smallTablet'
      ? 'text-4.5xl'
      : screenSize === 'tablet'
      ? 'text-5xl'
      : screenSize === 'intermediate'
      ? 'text-5.5xl'
      : 'text-6xl';

  return { titleFontSize, screenSize };
};

export default titleUtils;
