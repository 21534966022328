// src/UserPage.tsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowLeft,
  LogOut,
  Calendar,
  Edit,
  Check,
  X,
  Repeat,
  Gamepad2,
  ListChecks,
  Copy,
  Infinity as InfinityIcon,
} from 'lucide-react';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import { TopDonations } from './components/TopDonations';
import { motion } from 'framer-motion';
import LogoutModal from './components/LogoutModal';
import StatsTransferModal from './components/StatsTransferModal';
import CookieConsent from './components/CookieConsent';
import { getGames } from './games/registry';
import GameCard from './components/GameCard';
import {
  GameStat,
  getGameTags,
  sortGames,
  Game,
  getFavoriteGame,
} from './utils/gameUtils';
import { useTheme } from './context/ThemeContext';
import BackgroundTexture from './components/BackgroundTexture';
import { usePlayerLevel } from './utils/levelUtils';
import titleUtils from './utils/titleUtils';

interface Message {
  id: string;
  text: string;
  type: 'error' | 'info';
}

const UserPage: React.FC = () => {
  // Estados y hooks existentes
  const [isLoaded, setIsLoaded] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState<string>('');
  const [editingName, setEditingName] = useState('');
  const [accountCreationDate, setAccountCreationDate] = useState<Date | null>(
    null
  );
  const [totalGamesPlayed, setTotalGamesPlayed] = useState(0);
  const [favoriteGame, setFavoriteGame] = useState<{
    title: string;
    id: number;
  }>({ title: '-', id: 0 });
  const [nameError, setNameError] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showStatsTransferModal, setShowStatsTransferModal] = useState(false);
  const navigate = useNavigate();
  const games = getGames();
  const { isDarkMode } = useTheme();

  const MAX_NAME_LENGTH = 20;
  const [messages, setMessages] = useState<Message[]>([]);

  const addMessage = (text: string, type: 'error' | 'info') => {
    const id = crypto.randomUUID();
    setMessages([{ id, text, type }]);
  };

  const initialGameStats = useMemo(
    () =>
      games.map((game) => ({
        id: game.info.id,
        gamesPlayed: 0,
        maxRound: 0,
      })),
    [games]
  );

  const [gameStats, setGameStats] = useState<GameStat[]>(() =>
    games.map((game) => ({
      id: game.info.id,
      gamesPlayed: game.getStats()?.gamesPlayed || 0,
      maxRound: game.getStats()?.maxRound || 0,
      customStat: game.getStats()?.customStat || 0,
      gameName: game.info.title,
    }))
  );

  const mostPlayedGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) =>
        current.gamesPlayed > prev.gamesPlayed ? current : prev,
      { id: 0, gamesPlayed: 0, maxRound: 0, customStat: 0, gameName: '' }
    );
  }, [gameStats]);

  const bestRoundGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) => (current.maxRound > prev.maxRound ? current : prev),
      { id: 0, gamesPlayed: 0, maxRound: 0, customStat: 0, gameName: '' }
    );
  }, [gameStats]);

  const updateStats = useCallback(() => {
    let totalGames = 0;
    let bestRound = { id: 0, maxRound: 0 };

    games.forEach((game) => {
      const stats = game.getStats();
      if (stats && stats.gamesPlayed !== undefined) {
        totalGames += stats.gamesPlayed;
        if (stats.maxRound * 2 > bestRound.maxRound) {
          bestRound = { id: game.info.id, maxRound: stats.maxRound * 2 };
        }
      }
    });

    setTotalGamesPlayed(totalGames);

    // Obtener las estadísticas simplificadas para pasar a getFavoriteGame
    const simplifiedStats = gameStats.map((gs) => ({
      id: gs.id,
      gamesPlayed: gs.gamesPlayed,
    }));

    const favorite = getFavoriteGame(games, simplifiedStats);
    setFavoriteGame(favorite);
    setBestRoundGameId(bestRound.id);
  }, [games, gameStats]);

  const [bestRoundGameId, setBestRoundGameId] = useState<number>(0);

  useEffect(() => {
    let currentUserId = localStorage.getItem('userId');

    if (!currentUserId) {
      currentUserId = crypto.randomUUID().substring(0, 8);
      localStorage.setItem('userId', currentUserId);
    }

    setUserId(currentUserId);

    const savedName = localStorage.getItem('userName');
    if (!savedName) {
      navigate('/');
      return;
    }

    setUserName(savedName);

    const savedDate = localStorage.getItem('accountCreationDate');
    if (savedDate) {
      setAccountCreationDate(new Date(savedDate));
    } else {
      const newCreationDate = new Date();
      localStorage.setItem(
        'accountCreationDate',
        newCreationDate.toISOString()
      );
      setAccountCreationDate(newCreationDate);
    }

    updateStats();
    setIsLoaded(true);

    const handleNameChange = (event: CustomEvent) => {
      setUserName(event.detail.name);
    };

    const handleStatsImported = () => {
      updateStats();
      const updatedStats = games.map((game) => ({
        id: game.info.id,
        gamesPlayed: game.getStats()?.gamesPlayed || 0,
        maxRound: game.getStats()?.maxRound || 0,
        customStat: game.getStats()?.customStat || 0,
        gameName: game.info.title,
      }));
      setGameStats(updatedStats);
    };

    const handleAccountCreationDateChange = () => {
      const savedDate = localStorage.getItem('accountCreationDate');
      if (savedDate) {
        setAccountCreationDate(new Date(savedDate));
      }
    };
    const handleUserIdChange = () => {
      setUserId(localStorage.getItem('userId') || '');
    };

    window.addEventListener('statsImported', handleStatsImported);
    window.addEventListener(
      'accountCreationDateChanged',
      handleAccountCreationDateChange
    );

    window.addEventListener(
      'userNameChanged',
      handleNameChange as EventListener
    );
    window.addEventListener(
      'userIdChanged',
      handleUserIdChange as EventListener
    );

    return () => {
      window.removeEventListener('statsImported', handleStatsImported);
      window.removeEventListener(
        'accountCreationDateChanged',
        handleAccountCreationDateChange
      );
      window.removeEventListener(
        'userNameChanged',
        handleNameChange as EventListener
      );
      window.removeEventListener(
        'userIdChanged',
        handleUserIdChange as EventListener
      );
    };
  }, [updateStats, games, navigate]);

  const handleCopyId = async () => {
    try {
      await navigator.clipboard.writeText(userId);
      addMessage('ID copiado exitosamente.', 'info');
    } catch (error) {
      addMessage('Error al copiar el ID. Inténtalo de nuevo.', 'error');
    }
  };

  const clearSpecificCache = () => {
    // Itera sobre todos los juegos y elimina sus claves de estadísticas
    games.forEach((game) => {
      localStorage.removeItem(game.localStorageKey);
    });

    // Reinicia los estados relacionados en la aplicación
    setGameStats(
      games.map((game) => ({
        id: game.info.id,
        gamesPlayed: 0,
        maxRound: 0,
        customStat: 0,
        gameName: game.info.title,
      }))
    );

    // Elimina la Fecha de Creación
    localStorage.removeItem('accountCreationDate');
    setAccountCreationDate(null);
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const handleChangeName = async () => {
    if (!editingName.trim()) {
      setNameError('El nom no pot estar buit');
      return;
    }
    if (editingName.length > MAX_NAME_LENGTH) {
      setNameError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }

    try {
      localStorage.setItem('userName', editingName);
      setUserName(editingName);
      setIsEditingName(false);
      setNameError('');
      window.dispatchEvent(
        new CustomEvent('userNameChanged', {
          detail: { name: editingName },
        })
      );
      addMessage('Nom actualitzat correctamente.', 'info');
    } catch (error) {
      console.error('Error en actualitzar el nom:', error);
      setNameError(
        'Error en actualitzar el nom. Si us plau, intenta-ho de nou més tard.'
      );
    }
  };

  const handleLocalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setEditingName(value);
    }
  };

  const handleCancelNameEdit = () => {
    setIsEditingName(false);
    setEditingName(userName);
    setNameError('');
  };

  const handleEditMode = () => {
    setIsEditingName(true);
    setEditingName(userName);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleChangeName();
    }
  };

  const handleButtonMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.currentTarget.blur(); // Quitar el borde al presionar
  };

  const userInfoVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: 'easeOut',
      },
    }),
  };

  const formatDate = (date: Date | null): string => {
    if (!date) return '-';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatNumberWithCommas = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const orderedGames = useMemo(() => {
    return sortGames(games, gameStats, mostPlayedGame.id, bestRoundGameId);
  }, [games, gameStats, mostPlayedGame.id, bestRoundGameId]);

  // Detectar si es un dispositivo móvil
  const isMobile = useMemo(() => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }, []);

  const {
    level,
    progress,
    animatedLevel,
    percentageToNextLevel,
    ringControls,
    playerIconControls,
    progressControls,
  } = usePlayerLevel({
    totalGamesPlayed,
    disableAnimation: isMobile,
  });

  const adjustedProgress = Math.min(Math.max(progress, 0.1), 100);

  const circumference = 2 * Math.PI * 70;

  const displayLevel = useMemo(() => {
    if (level === 100) {
      return <InfinityIcon size={80} className="text-white" />;
    }
    return isMobile ? level : animatedLevel;
  }, [level, animatedLevel, isMobile]);

  const levelDisplayStyle = useMemo(() => {
    return isMobile ? { transform: 'none' } : {};
  }, [isMobile]);

  // Usa el hook para obtener el tamaño de fuente dinámico
  const { titleFontSize } = titleUtils();

  const createAccount = () => {
    // Lógica para crear la cuenta
    const userId = localStorage.getItem('userId');
    const stats = getStatsFromLocalStorage(); // Función que recupera las estadísticas

    if (userId) {
      // Importar estadísticas al crear la cuenta
      importStats(stats, userId);
    }
  };

  return (
    <div className="relative z-0">
      <BackgroundTexture className="background-texture" zIndex={-1} />
      <div
        className={`min-h-screen flex flex-col transition-colors duration-300 ${
          isLoaded ? '' : 'opacity-0'
        }`}
      >
        <div className="flex-grow px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
          <div className="max-w-7xl mx-auto">
            <header
              className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-10 opacity-0'
              }`}
            >
              <button onClick={() => navigate(-1)} className="group">
                <div className="icon-bg">
                  <ArrowLeft className="text-white w-1/2 h-1/2" />
                </div>
              </button>
              <h1
                className={`font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 absolute left-1/2 transform -translate-x-1/2 
                  ${titleFontSize}`}
              >
                Usuari
              </h1>
              <DarkModeToggle />
            </header>

            {/* Sección del Usuario */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6 mb-8"
            >
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="flex flex-col md:flex-row items-center mb-4 md:mb-2 w-full md:w-auto">
                  {/* Anillo de Progreso y Nivel */}
                  <motion.div
                    className="relative flex items-center justify-center mb-4 md:mb-0 md:mr-6"
                    style={{ width: '160px', height: '160px' }}
                  >
                    <div
                      className="icon-bg-no-animation icon-bg-no-animation-large flex items-center justify-center relative"
                      style={{ width: '140px', height: '140px' }}
                    >
                      <motion.div
                        className="flex flex-col items-center justify-center text-center text-white"
                        style={levelDisplayStyle}
                        animate={playerIconControls} // Animaciones para el icono del jugador
                      >
                        {level < 100 ? (
                          <>
                            <span className="text-sm font-medium">Nivell</span>
                            <span className="text-7xl font-extrabold">
                              {displayLevel}
                            </span>
                            <span className="text-sm font-medium">
                              {percentageToNextLevel}%
                            </span>
                          </>
                        ) : (
                          <InfinityIcon size={110} className="text-white" />
                        )}
                      </motion.div>
                    </div>

                    {/* Anillo de Progreso Animado */}
                    {level < 100 && (
                      <svg
                        className="absolute top-0 left-0"
                        width="160"
                        height="160"
                        style={{ transform: 'rotate(-90deg)' }}
                      >
                        <circle
                          className="text-gray-300 dark:text-gray-600"
                          stroke="currentColor"
                          fill="transparent"
                          strokeWidth="10"
                          strokeDasharray={circumference}
                          cx="80"
                          cy="80"
                          r="70"
                        />
                        {/* Reemplazar <circle> por <motion.circle> para animación */}
                        <motion.circle
                          className="text-yellow-500"
                          stroke="currentColor"
                          fill="transparent"
                          strokeWidth="10"
                          strokeLinecap="round"
                          strokeDasharray={circumference}
                          cx="80"
                          cy="80"
                          r="70"
                          initial={{ strokeDashoffset: circumference }}
                          animate={progressControls}
                          style={{
                            transform: 'rotate(0deg)',
                            transformOrigin: '50% 50%',
                          }}
                        />
                      </svg>
                    )}
                  </motion.div>

                  {/* Información del Usuario */}
                  <div className="text-center md:text-left flex-1">
                    {isEditingName ? (
                      <div className="flex flex-col items-start justify-center md:justify-start mb-2">
                        <div className="flex items-center">
                          <input
                            type="text"
                            value={editingName}
                            onChange={handleLocalNameChange}
                            onKeyPress={handleKeyPress}
                            className="w-full p-2 border-2 border-yellow-500 rounded-md bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                            maxLength={MAX_NAME_LENGTH}
                          />
                          <button
                            onClick={handleChangeName}
                            onMouseDown={handleButtonMouseDown}
                            className="text-green-500 mr-2 p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                          >
                            <Check size={20} />
                          </button>
                          <button
                            onClick={handleCancelNameEdit}
                            onMouseDown={handleButtonMouseDown}
                            className="text-red-500 p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                          >
                            <X size={20} />
                          </button>
                        </div>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                          {editingName.length}/{MAX_NAME_LENGTH} caràcters
                        </p>
                      </div>
                    ) : (
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white mb-2 flex items-center justify-center md:justify-start">
                          {userName}
                          <button
                            onClick={handleEditMode}
                            className="ml-2 text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1"
                          >
                            <Edit size={16} />
                          </button>
                        </h2>

                        <div className="mt-2 flex items-center space-x-2 text-gray-600 dark:text-gray-400 justify-center md:justify-start hidden md:flex">
                          <Calendar size={16} className="relative top-1" />
                          <p className="text-sm font-normal">
                            Data de Creació: {formatDate(accountCreationDate)}
                          </p>
                        </div>

                        <div className="mt-2 flex items-center space-x-2 text-gray-600 dark:text-gray-400 justify-center md:justify-start hidden md:flex">
                          <Gamepad2 size={16} className="relative top-1" />
                          <p className="text-sm font-normal">
                            Minijoc Preferit: {favoriteGame.title || '-'}
                          </p>
                        </div>

                        <div className="mt-2 flex items-center space-x-2 text-gray-600 dark:text-gray-400 justify-center md:justify-start hidden md:flex">
                          <ListChecks size={16} className="relative top-1" />
                          <p className="text-sm font-normal">
                            Total de Partides:{' '}
                            {formatNumberWithCommas(totalGamesPlayed)}
                          </p>
                        </div>
                      </motion.div>
                    )}

                    {nameError && (
                      <div className="mt-2 text-center text-red-500 bg-red-100 dark:bg-red-900/30 p-2 rounded">
                        {nameError}
                      </div>
                    )}
                  </div>
                </div>

                {/* Botones de Acción */}
                <div className="flex flex-col space-y-2 w-full md:w-auto">
                  <button
                    onClick={handleCopyId}
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 sm:py-2 sm:px-6 text-sm sm:text-base rounded-lg transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg hover:scale-105"
                  >
                    <Copy size={18} className="mr-1 sm:mr-2" />
                    Copiar ID
                  </button>
                  <button
                    onClick={handleLogout}
                    className="bg-[#ee5711] hover:bg-[#cc4709] text-white font-bold py-2 px-4 sm:py-2 sm:px-6 text-sm sm:text-base rounded-lg transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg hover:scale-105"
                  >
                    <LogOut size={18} className="mr-1 sm:mr-2" />
                    Tancar Sessió
                  </button>
                </div>
              </div>
            </motion.div>

            {/* Sección de Estadísticas */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.05 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6 mb-8"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 dark:text-white">
                  Estadístiques
                </h2>
                <button
                  onClick={() => setShowStatsTransferModal(true)}
                  className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 sm:px-4 py-1.5 sm:py-2  duration-300 text-xs sm:text-sm transform hover:scale-105"
                >
                  <Repeat size={16} className="mr-1" />
                  Transferir
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 align-items-end">
                {orderedGames.map((game) => {
                  const tags: GameTag[] = getGameTags(
                    game.info.id,
                    gameStats,
                    mostPlayedGame.id,
                    bestRoundGameId
                  );
                  return (
                    <GameCard
                      key={game.info.id}
                      info={game.info}
                      game={game}
                      stats={game.getStats()}
                      tags={tags}
                      isDarkMode={isDarkMode}
                    />
                  );
                })}
                {(() => {
                  const placeholders: JSX.Element[] = [];
                  if (orderedGames.length % 2 === 1) {
                    placeholders.push(
                      <div
                        key="proximamente-md"
                        className="bg-white dark:bg-gray-800 shadow-md rounded-lg flex items-center justify-center h-full md:block lg:hidden"
                      >
                        <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white truncate">
                          Pròximament
                        </span>
                      </div>
                    );
                  }
                  const remainderLg = orderedGames.length % 3;
                  if (remainderLg === 1) {
                    placeholders.push(
                      <div
                        key="proximamente-lg-1"
                        className="bg-white dark:bg-gray-800 shadow-md rounded-lg flex items-center justify-center h-full hidden lg:flex"
                      >
                        <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white truncate">
                          Pròximament
                        </span>
                      </div>,
                      <div
                        key="proximamente-lg-2"
                        className="bg-white dark:bg-gray-800 shadow-md rounded-lg flex items-center justify-center h-full hidden lg:flex"
                      >
                        <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white truncate">
                          Pròximament
                        </span>
                      </div>
                    );
                  } else if (remainderLg === 2) {
                    placeholders.push(
                      <div
                        key="proximamente-lg"
                        className="bg-white dark:bg-gray-800 shadow-md rounded-lg flex items-center justify-center h-full hidden lg:flex"
                      >
                        <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white truncate">
                          Pròximament
                        </span>
                      </div>
                    );
                  }
                  return placeholders;
                })()}
              </div>
            </motion.div>

            {/* Sección de Top Donations */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.1 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6"
            >
              <TopDonations limit={3} />
            </motion.div>
          </div>
        </div>
        <Footer />
        {/* Modales */}
        <LogoutModal
          isOpen={showLogoutModal}
          onClose={() => setShowLogoutModal(false)}
          onConfirm={confirmLogout}
          onClearSpecificCache={clearSpecificCache}
        />
        <StatsTransferModal
          isOpen={showStatsTransferModal}
          onClose={() => setShowStatsTransferModal(false)}
          userId={userId}
        />
      </div>
      <CookieConsent />
    </div>
  );
};

export default UserPage;
