// ConfigContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { INITIAL_DATA_VERSION } from '../data/initialDonations';

interface Config {
  donationsVersion: number;
  usersVersion: number;
  cacheTimeout: number; // ms
}

interface Version {
  number: number;
}

interface ConfigContextType {
  config: Config;
  version: Version | null;
  isLoading: boolean;
  error: string | null;
  refreshConfig: () => Promise<void>;
  configIsLoaded: boolean;
}

const DEFAULT_CONFIG: Config = {
  donationsVersion: 1,
  usersVersion: 1,
  // Para pruebas: 10000 ms (10 segundos). En producción: 21600000 ms (6 horas)
  cacheTimeout: 21600000,
};

export const ConfigContext = createContext<ConfigContextType | undefined>(
  undefined
);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<Config>(DEFAULT_CONFIG);
  const [version, setVersion] = useState<Version | null>(() => {
    // Cargar versión almacenada o usar la inicial
    const stored = localStorage.getItem('version');
    if (stored) {
      return JSON.parse(stored) as Version;
    }
    return { number: INITIAL_DATA_VERSION };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [configIsLoaded, setConfigIsLoaded] = useState(false);

  // Función para obtener config + versión de Firestore
  // y actualizar localStorage
  const fetchConfigFromFirestore = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Versión
      const versionDoc = await getDoc(doc(db, 'version', 'global'));
      let fetchedVersion: Version = { number: INITIAL_DATA_VERSION };

      if (versionDoc.exists()) {
        fetchedVersion = versionDoc.data() as Version;
      }

      // Config
      const configDoc = await getDoc(doc(db, 'config', 'global'));
      let newConfig = { ...DEFAULT_CONFIG };

      if (configDoc.exists()) {
        const c = configDoc.data() as Partial<Config>;
        newConfig = {
          donationsVersion:
            c.donationsVersion ?? DEFAULT_CONFIG.donationsVersion,
          usersVersion: c.usersVersion ?? DEFAULT_CONFIG.usersVersion,
          cacheTimeout: c.cacheTimeout ?? DEFAULT_CONFIG.cacheTimeout,
        };
      }

      // Actualizamos estado y localStorage
      setVersion(fetchedVersion);
      setConfig(newConfig);
      localStorage.setItem('version', JSON.stringify(fetchedVersion));
      localStorage.setItem('cacheTimeout', newConfig.cacheTimeout.toString());

      // Ponemos la hora de esta petición
      localStorage.setItem('lastFetchTimestamp', Date.now().toString());
    } catch (err) {
      console.error('[ConfigContext] Error fetching config:', err);
      setError('Error al obtener configuración. Usando valores por defecto.');
    } finally {
      setIsLoading(false);
      setConfigIsLoaded(true);
    }
  };

  // Llamado manualmente cuando timeLeft llega a 0 en el InformationModal
  const refreshConfig = async () => {
    await fetchConfigFromFirestore();
  };

  // Al montar, decidimos si hacemos fetch inmediato o no, según `lastFetchTimestamp` y `cacheTimeout`
  useEffect(() => {
    const lastFetchTsStr = localStorage.getItem('lastFetchTimestamp');
    const ctStr = localStorage.getItem('cacheTimeout');

    // Si no existe nada, primera vez => no llamamos al servidor,
    // mantenemos valores por defecto y guardamos en localStorage
    if (!lastFetchTsStr || !ctStr) {
      localStorage.setItem('lastFetchTimestamp', Date.now().toString());
      localStorage.setItem(
        'cacheTimeout',
        DEFAULT_CONFIG.cacheTimeout.toString()
      );
      setConfigIsLoaded(true);
      return;
    }

    // Si existen, calculamos si ha pasado el tiempo
    const now = Date.now();
    const lastFetch = parseInt(lastFetchTsStr, 10);
    const cTimeout = parseInt(ctStr, 10);
    const elapsed = now - lastFetch;

    if (elapsed >= cTimeout) {
      // Ha expirado => pedimos config
      fetchConfigFromFirestore();
    } else {
      // Aún no expira => no hacemos fetch, pero marcamos que la config está "cargada" (aunque sea la local)
      setConfigIsLoaded(true);
    }
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        config,
        version,
        isLoading,
        error,
        refreshConfig,
        configIsLoaded,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const ctx = useContext(ConfigContext);
  if (!ctx) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return ctx;
};
