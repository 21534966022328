// utils/diceUtils.ts
import { Dice1, Dice2, Dice3, Dice4, Dice5, Dice6 } from 'lucide-react';
import { Game } from './gameUtils';

export type DiceIcon =
  | typeof Dice1
  | typeof Dice2
  | typeof Dice3
  | typeof Dice4
  | typeof Dice5
  | typeof Dice6;

export const diceIcons: DiceIcon[] = [Dice1, Dice2, Dice3, Dice4, Dice5, Dice6];

let lastRolledGameId: string | null = null;

export const rollDice = (): number => {
  return Math.floor(Math.random() * 6);
};

export const getRandomGame = (
  games: Game[]
): { game: Game; diceRoll: number } | null => {
  if (!games || games.length === 0) {
    return null;
  }

  let randomGame: Game;
  let randomGameIndex: number;
  let attempts = 0;

  do {
    randomGameIndex = Math.floor(Math.random() * games.length);
    randomGame = games[randomGameIndex];
    attempts++;

    if (attempts > games.length * 2) {
      console.error('Could not find a unique random game. returning null');
      return null;
    }
  } while (
    typeof randomGame.info.id === 'number' &&
    String(randomGame.info.id) === lastRolledGameId
  );

  lastRolledGameId = String(randomGame.info.id);

  return { game: randomGame, diceRoll: rollDice() };
};
