import { useState, useEffect } from 'react';
import {
  getGameTags,
  GameTag,
  GameStat,
  Game,
  GAME_CATEGORIES,
} from './gameUtils';

interface SearchResults {
  filteredGames: any[];
  hiddenGames: string[];
  placeholdersHidden: boolean;
  suggestions: string[];
}

interface SearchOptions {
  searchValue: string;
  gameStats: GameStat[];
  mostPlayedGameId: string;
  bestRoundGameId: string;
  orderedGames: Game[];
}

export const useSearchLogic = ({
  searchValue,
  gameStats,
  mostPlayedGameId,
  bestRoundGameId,
  orderedGames,
}: SearchOptions): SearchResults => {
  const [hiddenGames, setHiddenGames] = useState<string[]>([]);
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [placeholdersHidden, setPlaceholdersHidden] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (!searchValue) {
      setHiddenGames([]);
      setFilteredGames(orderedGames);
      setPlaceholdersHidden(false);
      setSuggestions([]);
      return;
    }

    const searchTerm = searchValue.toLowerCase();
    const newFilteredGames = orderedGames.filter((game) => {
      const title = game.info.title.toLowerCase();
      const description = game.info.description?.toLowerCase() || '';
      const tags = getGameTags(
        game.info.id,
        gameStats,
        mostPlayedGameId,
        bestRoundGameId
      );
      const tagLabels = tags.map((tag) => tag.label.toLowerCase());
      const category = game.info.category.toLowerCase();

      const matchesSearch =
        title.includes(searchTerm) ||
        description.includes(searchTerm) ||
        tagLabels.some((label) => label.includes(searchTerm)) ||
        category.includes(searchTerm);

      if (!matchesSearch) {
        setHiddenGames((prevHidden) =>
          prevHidden.includes(game.info.id.toString())
            ? prevHidden
            : [...prevHidden, game.info.id.toString()]
        );
      } else {
        setHiddenGames((prevHidden) =>
          prevHidden.filter((id) => id !== game.info.id.toString())
        );
      }

      return matchesSearch;
    });

    if (newFilteredGames.length === 0) {
      setPlaceholdersHidden(true);
    } else {
      setPlaceholdersHidden(false);
    }

    const allSuggestions = orderedGames.reduce((acc: string[], game) => {
      acc.push(game.info.title);
      game.info.description && acc.push(game.info.description);

      const tags = getGameTags(
        game.info.id,
        gameStats,
        mostPlayedGameId,
        bestRoundGameId
      );
      tags.forEach((tag) => acc.push(tag.label));
      acc.push(game.info.category);

      return acc;
    }, []);

    const uniqueSuggestions = [...new Set(allSuggestions)].filter(
      (suggestion) =>
        suggestion.toLowerCase().includes(searchTerm) &&
        suggestion.toLowerCase() !== searchTerm
    );

    setSuggestions(uniqueSuggestions.slice(0, 5));
    setFilteredGames(newFilteredGames);
  }, [searchValue, orderedGames, gameStats, mostPlayedGameId, bestRoundGameId]);

  return { filteredGames, hiddenGames, placeholdersHidden, suggestions };
};
