import React, { useEffect, useRef, useCallback, FC } from 'react';
import { useTheme } from '../context/ThemeContext';

interface BackgroundTextureProps {
  className?: string;
  svgSize?: number;
  spacing?: number;
  strokeWidth?: number;
  baseOpacityLight?: number;
  baseOpacityDark?: number;
  highlightOpacityLight?: number;
  highlightOpacityDark?: number;
  highlightScale?: number;
  maxOffset?: number;
  radius?: number;
  normalFillColor?: string;
  normalStrokeColor?: string;
  darkFillColor?: string;
  darkStrokeColor?: string;
  positionType?: 'fixed' | 'absolute';
  zIndex?: number;
  padding?: number;
}

const BackgroundTexture: FC<BackgroundTextureProps> = React.memo(
  ({
    className = '',
    svgSize = 30,
    spacing = 50,
    strokeWidth = 1,
    baseOpacityLight = 0.09,
    baseOpacityDark = 0.03,
    highlightOpacityLight = 0.8,
    highlightOpacityDark = 1,
    highlightScale = 1.5,
    maxOffset = 20,
    radius = 90,
    normalFillColor = '#ee5711',
    normalStrokeColor = '#ee5711',
    darkFillColor = '#ffffff',
    darkStrokeColor = '#ffffff',
    positionType = 'fixed',
    zIndex = 0,
    padding = 0,
  }) => {
    const { isDarkMode } = useTheme();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const cursorRef = useRef<{ x: number; y: number }>({ x: -1000, y: -1000 });
    const isAnimatedRef = useRef<boolean>(true);
    const animationFrameIdRef = useRef<number | null>(null);
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    useEffect(() => {
      const checkIsMobile = () => {
        setIsMobile(
          /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        );
      };

      checkIsMobile();
      window.addEventListener('resize', checkIsMobile, { passive: true });
      return () => {
        window.removeEventListener('resize', checkIsMobile);
      };
    }, []);

    useEffect(() => {
      const mediaQuery = window.matchMedia(
        '(hover: none) and (pointer: coarse)'
      );
      const handleMediaChange = (e: MediaQueryListEvent) => {
        isAnimatedRef.current = !e.matches;
      };
      isAnimatedRef.current = !mediaQuery.matches;
      mediaQuery.addEventListener('change', handleMediaChange);
      return () => mediaQuery.removeEventListener('change', handleMediaChange);
    }, []);

    useEffect(() => {
      if (!isAnimatedRef.current) return;

      const handleMouseMove = (e: MouseEvent) => {
        cursorRef.current = {
          x: e.clientX,
          y: e.clientY,
        };
      };

      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);

    const resizeCanvas = useCallback(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.width = window.innerWidth - padding * 2;
        canvas.height = window.innerHeight - padding * 2;
        drawCanvas();
      }
    }, [padding]);

    const drawCanvas = useCallback(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = `${svgSize - 10}px sans-serif`;
      ctx.lineWidth = strokeWidth;

      const fillColor = isDarkMode ? darkFillColor : normalFillColor;
      const strokeColor = isDarkMode ? darkStrokeColor : normalStrokeColor;

      const baseOpacity = isDarkMode ? baseOpacityDark : baseOpacityLight;
      const highlightOpacity = isDarkMode
        ? highlightOpacityDark
        : highlightOpacityLight;

      const adjustedSpacing = isMobile ? spacing * 0.8 : spacing;
      const adjustedSvgSize = isMobile ? svgSize * 1 : svgSize;

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      const cols = Math.ceil(canvas.width / adjustedSpacing);
      const rows = Math.ceil(canvas.height / adjustedSpacing);

      // Asegurarse de que haya un número impar de columnas y filas
      const adjustedCols = cols % 2 === 0 ? cols + 1 : cols;
      const adjustedRows = rows % 2 === 0 ? rows + 1 : rows;

      const startX = centerX - ((adjustedCols - 1) * adjustedSpacing) / 2;
      const startY = centerY - ((adjustedRows - 1) * adjustedSpacing) / 2;

      for (let row = 0; row < adjustedRows; row++) {
        for (let col = 0; col < adjustedCols; col++) {
          const x = startX + col * adjustedSpacing;
          const y = startY + row * adjustedSpacing;

          if (x < 0 || x > canvas.width || y < 0 || y > canvas.height) {
            continue;
          }

          const dx = cursorRef.current.x - x;
          const dy = cursorRef.current.y - y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          let offsetX = 0;
          let offsetY = 0;
          let scale = 1;
          let currentOpacity = baseOpacity;

          if (isAnimatedRef.current && distance < radius && distance !== 0) {
            const force = (1 - distance / radius) * maxOffset;
            offsetX = (dx / distance) * force;
            offsetY = (dy / distance) * force;
            scale = 1 + ((radius - distance) / radius) * (highlightScale - 1);
            currentOpacity =
              baseOpacity +
              ((highlightOpacity - baseOpacity) * (radius - distance)) / radius;
          }

          ctx.save();
          ctx.globalAlpha = currentOpacity;
          ctx.translate(x + offsetX, y + offsetY);
          ctx.scale(scale, scale);
          ctx.fillStyle = fillColor;
          ctx.strokeStyle = strokeColor;
          ctx.fillText('+', 0, 0);
          ctx.strokeText('+', 0, 0);
          ctx.restore();
        }
      }
    }, [
      isDarkMode,
      normalFillColor,
      normalStrokeColor,
      darkFillColor,
      darkStrokeColor,
      spacing,
      svgSize,
      strokeWidth,
      baseOpacityLight,
      baseOpacityDark,
      highlightOpacityLight,
      highlightOpacityDark,
      highlightScale,
      maxOffset,
      radius,
      isMobile,
    ]);

    const animate = useCallback(() => {
      drawCanvas();
      animationFrameIdRef.current = requestAnimationFrame(animate);
    }, [drawCanvas]);

    useEffect(() => {
      resizeCanvas();
      animationFrameIdRef.current = requestAnimationFrame(animate);

      return () => {
        if (animationFrameIdRef.current !== null) {
          cancelAnimationFrame(animationFrameIdRef.current);
        }
      };
    }, [animate, resizeCanvas]);

    useEffect(() => {
      window.addEventListener('resize', resizeCanvas, { passive: true });
      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }, [resizeCanvas]);

    return (
      <div
        className="flex justify-center items-center"
        style={{
          position: positionType,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: zIndex,
          padding: `${padding}px`,
          pointerEvents: 'none',
        }}
      >
        <canvas
          ref={canvasRef}
          className={`${className} pointer-events-none`}
          aria-hidden="true"
        />
      </div>
    );
  }
);

export default BackgroundTexture;
