import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface GameRoundProps {
  currentRound: number;
  roundProgress: number;
  restartCount: number;
  hasLost: boolean;
}

const GameRound: React.FC<GameRoundProps> = ({
  currentRound,
  roundProgress,
  restartCount,
  hasLost,
}) => {
  const [barWidth, setBarWidth] = useState(5);
  const barAnimation = useAnimation();
  const roundTextAnimation = useAnimation();
  const MIN_BAR_WIDTH = 5;
  const animationDuration = 0.4;

  // Referencias para controlar cambios
  const roundRef = useRef(currentRound);
  const firstRender = useRef(true);
  const progressRef = useRef(roundProgress);
  const lastRestartRef = useRef(restartCount);

  useEffect(() => {
    // Primer render
    if (firstRender.current) {
      setBarWidth(Math.max(MIN_BAR_WIDTH, roundProgress));
      barAnimation.set({ width: `${Math.max(MIN_BAR_WIDTH, roundProgress)}%` });
      firstRender.current = false;
      roundRef.current = currentRound; // Aseguramos que coincida al inicio
      return;
    }

    // Si se detecta que hicimos un "restart" (cambió el resetCounter)
    if (restartCount !== lastRestartRef.current) {
      lastRestartRef.current = restartCount;

      // Reseteamos la barra a un mínimo
      setBarWidth(MIN_BAR_WIDTH);
      barAnimation.start({
        width: `${MIN_BAR_WIDTH}%`,
        transition: { duration: animationDuration, ease: 'easeInOut' },
      });

      // También actualizamos la referencia de ronda
      roundRef.current = currentRound;
      return;
    }

    // Si cambia la ronda
    if (currentRound !== roundRef.current) {
      // Animación del texto de la ronda
      roundTextAnimation
        .start({
          scale: 1.15,
          transition: { duration: animationDuration / 2, ease: 'easeOut' },
        })
        .then(() => {
          roundTextAnimation.start({
            scale: 1,
            transition: { duration: animationDuration / 2, ease: 'easeIn' },
          });
        });

      // Si no hemos perdido, animamos la barra
      if (!hasLost) {
        // Al pasar de una ronda a la siguiente, la barra "se llena"
        // y luego vuelve a reiniciarse
        if (currentRound > 1) {
          barAnimation
            .start({
              width: '100%',
              transition: { duration: animationDuration, ease: 'easeInOut' },
            })
            .then(() => {
              barAnimation.start({
                width: `${MIN_BAR_WIDTH}%`,
                transition: { duration: animationDuration, ease: 'easeInOut' },
              });
              setBarWidth(MIN_BAR_WIDTH);
            });
        }
      }

      // Actualizamos la referencia de ronda
      roundRef.current = currentRound;
    } else {
      // Si la ronda no cambia pero sí sube el progreso
      if (roundProgress > progressRef.current) {
        const newWidth = Math.max(MIN_BAR_WIDTH, roundProgress);
        barAnimation.start({
          width: `${newWidth}%`,
          transition: { duration: animationDuration, ease: 'easeInOut' },
        });
        setBarWidth(newWidth);
      }
    }

    progressRef.current = roundProgress;
  }, [
    currentRound,
    roundProgress,
    restartCount,
    hasLost,
    barAnimation,
    roundTextAnimation,
  ]);

  return (
    <div className="bg-gray-300 dark:bg-gray-600 rounded-md flex items-center mx-2 py-1 px-2 relative overflow-hidden">
      <div className="absolute inset-0 z-0 rounded-md overflow-hidden">
        <motion.div
          className="h-full bg-yellow-500"
          style={{ width: `${barWidth}%` }}
          animate={barAnimation}
        />
      </div>
      <motion.div
        className="flex items-center relative z-20"
        animate={roundTextAnimation}
      >
        <span
          style={{ marginRight: currentRound < 10 ? '-0.5rem' : '0' }}
          className="font-semibold text-xl"
        >
          Ronda
        </span>
        <span className="text-xl text-end inline-block min-w-[30px]">
          {currentRound}
        </span>
      </motion.div>
    </div>
  );
};

export default GameRound;
