// src/data/initialDonations.ts
export const INITIAL_DATA_VERSION = 1;

export const initialTopDonations = [
  { name: 'A', amount: 30.0 },
  { name: 'B', amount: 20.0 },
  { name: 'C', amount: 10.0 },
];

export const initialRecentDonations = [
  { name: 'A', amount: 5.0, date: '2025-01-02' },
  { name: 'B', amount: 10.0, date: '2025-01-08' },
  { name: 'C', amount: 2.0, date: '2025-01-14' },
];
