import React, { createContext, useState, useContext, useEffect } from 'react';

type ThemeContextType = {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Comprobamos la preferencia del navegador
    const prefersDarkMode =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    // Si existe la preferencia guardada, la usamos, sino la del navegador
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      return JSON.parse(savedMode);
    } else {
      return prefersDarkMode;
    }
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));

    // Aplicar clase dark al HTML
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    // Enviar evento a Android
    try {
      if (window.Android) {
        window.Android.onThemeChanged(isDarkMode);
      }
    } catch (error) {
      console.error('Error sending theme change to Android:', error);
    }

    // Enviar evento a navegadores
    try {
      // Evento personalizado para navegadores
      const themeEvent = new CustomEvent('themeChange', {
        detail: { isDarkMode },
      });
      window.dispatchEvent(themeEvent);

      // Actualizar meta theme-color
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute(
          'content',
          isDarkMode ? '#1f2937' : '#ffffff'
        );
      }

      // Actualizar color scheme para navegadores
      document.documentElement.style.setProperty(
        'color-scheme',
        isDarkMode ? 'dark' : 'light'
      );
    } catch (error) {
      console.error('Error sending theme change to browsers:', error);
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

// Añadir tipos para TypeScript
declare global {
  interface Window {
    Android?: {
      onThemeChanged: (isDarkMode: boolean) => void;
    };
  }
}
