import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Copy, X, Clipboard } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import BaseModal from './BaseModal';
import { motion, AnimatePresence } from 'framer-motion';

interface IdentityModalProps {
  isOpen: boolean;
  onClose: () => void;
  userName: string;
  fromDonatePage?: boolean;
  onModalSubmit?: () => void;
}

interface Message {
  id: string;
  text: string;
  type: 'error' | 'info';
}

const IdentityModal: React.FC<IdentityModalProps> = ({
  isOpen,
  onClose,
  userName,
  fromDonatePage = false,
  onModalSubmit,
}) => {
  const [mode, setMode] = useState<'create' | 'login'>('create');
  const [newName, setNewName] = useState('');
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const defaultName = 'El teu nom';
  const defaultId = 'El teu ID';
  const MAX_NAME_LENGTH = 20;
  const MAX_ID_LENGTH = 8;
  const [message, setMessage] = useState<Message | null>(null);
  const messageTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const addMessage = useCallback(
    (text: string, type: 'error' | 'info') => {
      const newId = crypto.randomUUID();
      const newMessage = { id: newId, text, type };
      setMessage(newMessage);
      // Clear any existing timeout
      if (messageTimeout.current) {
        clearTimeout(messageTimeout.current);
      }

      // Set a timeout to clear the message after 3 seconds
      messageTimeout.current = setTimeout(() => {
        setMessage(null);
      }, 4000);
    },
    [setMessage]
  );

  useEffect(() => {
    if (isOpen) {
      setMode('create');
    }
    const storedName = localStorage.getItem('userName');
    if (storedName) {
      setNewName(storedName);
    } else if (userName) {
      setNewName(userName);
    } else {
      setNewName('');
    }
  }, [userName, isOpen]);

  const handleClose = () => {
    setNewName('');
    setUserId('');
    setError('');
    setIsSubmitting(false);
    setMessage(null);
    onClose();
  };

  const handlePasteCode = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      const truncatedText = clipboardText.slice(0, MAX_ID_LENGTH);
      setUserId(truncatedText);
      addMessage('ID enganxat des del portapapers.', 'info');
    } catch (err) {
      addMessage(
        'Error al enganxar el text. Assegura’t de permetre l’accés al portapapers.',
        'error'
      );
    }
  };

  const handleCreateAccount = async () => {
    setError('');

    if (!newName.trim()) {
      setError('Si us plau, introdueix un nom');
      return;
    }

    if (newName.length > MAX_NAME_LENGTH) {
      setError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }

    try {
      setIsSubmitting(true);

      // Save name to localStorage
      localStorage.setItem('userName', newName);

      // Dispatch event to notify name change
      window.dispatchEvent(
        new CustomEvent('userNameChanged', {
          detail: { name: newName },
        })
      );

      // If a userId doesn't already exist, create one
      let currentUserId = localStorage.getItem('userId');
      if (!currentUserId) {
        currentUserId = crypto.randomUUID().substring(0, 8); // Tomar los primeros 8 caracteres
        localStorage.setItem('userId', currentUserId);
        // Dispatch event to notify ID change
        window.dispatchEvent(
          new CustomEvent('userIdChanged', {
            detail: { id: currentUserId },
          })
        );
      }

      handleClose();
      if (fromDonatePage && onModalSubmit) {
        onModalSubmit();
      } else if (!fromDonatePage) {
        navigate('/usuari');
      }
    } catch (error) {
      console.error('Error guardant el nom:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(
          'Error al guardar el nom. Si us plau, intenta-ho de nou més tard.'
        );
      }
      setIsSubmitting(false);
    }
  };

  const handleLogin = async () => {
    setError('');
    if (!newName.trim()) {
      setError('Si us plau, introdueix un nom');
      return;
    }
    if (newName.length > MAX_NAME_LENGTH) {
      setError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }
    if (!userId.trim()) {
      addMessage('Si us plau, introdueix un ID', 'error');
      return;
    }

    // Validar el formato del ID con una expresión regular
    const userIdRegex = /^[0-9a-f]{8}$/i;
    if (!userIdRegex.test(userId)) {
      addMessage('Si us plau, introdueix un ID amb format vàlid.', 'error');
      return;
    }

    try {
      setIsSubmitting(true);

      // Save name to localStorage
      localStorage.setItem('userName', newName);

      // Dispatch event to notify name change
      window.dispatchEvent(
        new CustomEvent('userNameChanged', {
          detail: { name: newName },
        })
      );

      // Save the new ID
      localStorage.setItem('userId', userId);

      // Dispatch event to notify ID change
      window.dispatchEvent(
        new CustomEvent('userIdChanged', {
          detail: { id: userId },
        })
      );

      handleClose();
      if (fromDonatePage && onModalSubmit) {
        onModalSubmit();
      } else if (!fromDonatePage) {
        navigate('/usuari');
      }
    } catch (error) {
      console.error('Error guardant el nom:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(
          'Error al guardar el nom. Si us plau, intenta-ho de nou més tard.'
        );
      }
      setIsSubmitting(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (newName.length <= MAX_NAME_LENGTH) {
      setNewName(newName);
    }
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUserId = e.target.value;
    if (newUserId.length <= MAX_ID_LENGTH) {
      setUserId(newUserId);
    }
  };

  const handleModeChange = (mode: 'create' | 'login') => {
    setMode(mode);
    setError('');
    setMessage(null);
    setUserId(''); // Borrar el userId al cambiar de modo
  };

  const contentVariants = {
    initial: (direction: number) => ({
      x: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
    exit: (direction: number) => ({
      x: direction > 0 ? -50 : 50,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    }),
  };

  const messageVariants = {
    initial: { opacity: 0, y: 10 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.2, ease: 'easeOut' },
    },
    exit: { opacity: 0, y: 10, transition: { duration: 0.15, ease: 'easeIn' } },
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="md"
      className="relative"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Accedir
        </h2>
        <button
          onClick={handleClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
      <div className="flex rounded-lg bg-gray-100 dark:bg-gray-700 p-1 mb-2">
        <button
          onClick={() => handleModeChange('create')}
          className={`flex-1 py-2 px-4 rounded-md transition-colors ${
            mode === 'create'
              ? 'bg-gradient-to-r from-yellow-500 to-red-500 text-white'
              : 'text-gray-700 dark:text-gray-300'
          }`}
        >
          Crear Compte
        </button>
        <button
          onClick={() => handleModeChange('login')}
          className={`flex-1 py-2 px-4 rounded-md transition-colors ${
            mode === 'login'
              ? 'bg-gradient-to-r from-yellow-500 to-red-500 text-white'
              : 'text-gray-700 dark:text-gray-300'
          }`}
        >
          Iniciar Sessió
        </button>
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {mode === 'create' ? (
          <motion.div
            key="create"
            custom={1}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            layout
            className="space-y-4"
          >
            <div className="space-y-2 mb-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
              <p>Introdueix el teu nom per crear un compte.</p>
              <p>
                Pots canviar el teu nom quan ho necessitis i explorar la teva
                pàgina, on trobaràs el teu minijoc preferit, les estadístiques
                del teu progrés i altres opcions per gestionar el teu perfil.
              </p>
              <p>Gràcies per formar part d'aquesta aventura!</p>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateAccount();
              }}
              className="space-y-4"
            >
              <div>
                <input
                  type="text"
                  value={newName}
                  onChange={handleNameChange}
                  placeholder={defaultName}
                  className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  required
                  maxLength={MAX_NAME_LENGTH}
                />
                <p className="!mt-2 text-sm text-gray-500 dark:text-gray-400">
                  {newName.length}/{MAX_NAME_LENGTH} caràcters
                </p>
              </div>

              {error && (
                <p className="text-red-500 text-sm bg-red-100 dark:bg-red-900/30 p-2.5 rounded-lg">
                  {error}
                </p>
              )}

              <div className="pt-2">
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2.5 px-4 rounded-lg transition duration-300 text-base"
                >
                  Acceptar
                </motion.button>
              </div>
            </form>
          </motion.div>
        ) : (
          <motion.div
            key="login"
            custom={-1}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            layout
            className="space-y-4"
          >
            <div className="space-y-2 mb-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
              <p>
                Introdueix el teu nom i ID per vincular les teves donacions al
                teu compte i participar al Top de Donacions.
              </p>
              <p>
                Si ja has donat abans, utilitza el mateix ID de la teva pàgina
                d'usuari per associar les noves donacions al teu compte. Pots
                trobar l'ID fent clic al botó{' '}
                <span className="inline-flex items-center">
                  {'"'}
                  <Copy
                    size={16}
                    className="inline-block align-text-bottom mx-1"
                  />
                  Copiar ID
                  {'"'}
                </span>{' '}
                dins de la teva pàgina d'usuari.
              </p>
              <p>
                És important no compartir el teu ID amb ningú, ja que serveix
                per garantir que totes les teves donacions s'acumulin
                correctament al teu perfil.
              </p>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
              className="space-y-4"
            >
              <div>
                <input
                  type="text"
                  value={newName}
                  onChange={handleNameChange}
                  placeholder={defaultName}
                  className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  required
                  maxLength={MAX_NAME_LENGTH}
                />
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  {newName.length}/{MAX_NAME_LENGTH} caràcters
                </p>
              </div>
              <div className="relative">
                <input
                  type="text"
                  value={userId}
                  onChange={handleIdChange}
                  placeholder={defaultId}
                  className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  required
                  maxLength={MAX_ID_LENGTH}
                />
                <button
                  type="button"
                  onClick={handlePasteCode}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1.5 rounded-lg bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors"
                  aria-label="Enganxar codi d'estadístiques"
                >
                  <Clipboard size={16} />
                </button>
              </div>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {userId.length}/{MAX_ID_LENGTH} caràcters
              </p>
              {error && (
                <p className="text-red-500 text-sm bg-red-100 dark:bg-red-900/30 p-2.5 rounded-lg">
                  {error}
                </p>
              )}
              <AnimatePresence>
                {message && (
                  <motion.p
                    key={message.id}
                    variants={messageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className={`text-sm p-2.5 rounded-lg ${
                      message.type === 'error'
                        ? 'text-red-500 bg-red-50 dark:bg-red-900/30'
                        : 'text-green-500 bg-green-100 dark:bg-green-900/30'
                    }`}
                  >
                    {message.text}
                  </motion.p>
                )}
              </AnimatePresence>
              <div className="pt-2">
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2.5 px-4 rounded-lg transition duration-300 text-base"
                >
                  Acceptar
                </motion.button>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </BaseModal>
  );
};

export default IdentityModal;
