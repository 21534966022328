import React, { FC, useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Game } from '../utils/gameUtils';

interface GameStats {
  gamesPlayed: number;
  maxRound: number;
  customStat: number;
  gameName: string;
}

interface CurrentGameStats {
  customStat: number;
}

interface GameLostProps {
  currentRound: number;
  onRestart: () => void;
  onExit: () => void;
  isRestartCooldown: boolean;
  cooldownSeconds: number;
  showFinishedAnimation: boolean;
  countdownKey: number;
  stats: GameStats; // <-- Usamos stats para ver maxRound, gamesPlayed
  currentGameStats: CurrentGameStats;
  customStatLabel: string;
  customStatLabelSingular: string;
  gameInstructions: string[];
  gameName: string;
  game: Game;
}

const GameLost: FC<GameLostProps> = ({
  currentRound,
  onRestart,
  onExit,
  isRestartCooldown,
  cooldownSeconds,
  showFinishedAnimation,
  countdownKey,
  stats,
  currentGameStats,
  customStatLabel,
  customStatLabelSingular,
  gameInstructions,
  gameName,
  game,
}) => {
  const [recordMessage, setRecordMessage] = useState<
    'nou' | 'igualat' | 'millor' | null
  >(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const restartButtonColor = '#ee5711';

  // Para actualizar isMobile en caso de redimensionar
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Cálculos internos de récord
  const previousBestValue = stats?.maxRound ?? 0;
  const isFirstGame = stats.gamesPlayed <= 1;
  const isNewRecord = currentRound > previousBestValue;
  const isTie = currentRound === previousBestValue;

  // Decide si mostrar "nou", "igualat" o "millor"
  useEffect(() => {
    if (isFirstGame) {
      setRecordMessage('nou');
    } else if (isNewRecord) {
      setRecordMessage('nou');
    } else if (isTie) {
      setRecordMessage('igualat');
    } else {
      setRecordMessage('millor');
    }
  }, [
    isFirstGame,
    isNewRecord,
    isTie,
    currentRound,
    previousBestValue,
    stats.gamesPlayed,
  ]);

  // Ejemplo: customStatValue = número de clics totales
  const customStatValue = React.useMemo(() => {
    return currentGameStats?.customStat ?? 0;
  }, [currentGameStats]);

  return (
    <div className="space-y-6">
      <div className="text-gray-600 dark:text-gray-300">
        <h3 className="text-lg font-semibold mb-4 text-center">
          <div className="space-y-4">
            <div
              className="text-2xl font-bold relative"
              style={{ color: restartButtonColor }}
            >
              Final!
            </div>
            <div className="mt-4">
              {gameInstructions.map((paragraph, index) => (
                <p key={index} className="mb-1 text-center text-base">
                  {paragraph}
                </p>
              ))}
            </div>
            <div className="bg-gray-100 dark:bg-gray-700 p-4 mt-4 rounded-lg">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-6 items-center">
                {/* Ronda actual */}
                <div className="flex flex-col items-center">
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    Ronda
                  </div>
                  <div className="text-3xl font-bold text-gray-800 dark:text-gray-200">
                    {currentRound}
                  </div>
                </div>

                {/* Mensaje de récord (solo en desktop o tablet, no en mobile) */}
                {!isMobile && (
                  <div className="flex flex-col items-center justify-center">
                    {recordMessage === 'nou' && (
                      <div className="text-2xl font-bold text-yellow-500 dark:text-yellow-400 animate-pulse whitespace-nowrap">
                        Nou Rècord!
                      </div>
                    )}
                    {recordMessage === 'igualat' && (
                      <div className="text-2xl font-bold text-yellow-500 dark:text-yellow-400 animate-pulse whitespace-nowrap">
                        Rècord Igualat!
                      </div>
                    )}
                    {recordMessage === 'millor' && (
                      <>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          Millor Ronda
                        </div>
                        <div className="text-3xl font-bold text-gray-800 dark:text-gray-200">
                          {previousBestValue}
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* Estadística personalizada (p.e. clics) */}
                <div className="flex flex-col items-center">
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {customStatValue === 1
                      ? customStatLabelSingular
                      : customStatLabel}
                  </div>
                  <div className="text-3xl font-bold text-gray-800 dark:text-gray-200">
                    {customStatValue}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </h3>
      </div>

      {/* Botones de Reiniciar y Salir */}
      <div className="flex flex-col space-y-3">
        <div className="flex space-x-3">
          <button
            onClick={onRestart}
            disabled={isRestartCooldown}
            className={`bg-[#ee5711] hover:bg-[#cc4709] text-white font-bold py-2 px-4 sm:py-2 sm:px-6 text-sm sm:text-base rounded-lg transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg relative flex-1 ${
              isRestartCooldown
                ? 'cursor-not-allowed active:scale-100'
                : 'hover:scale-105'
            }`}
          >
            <span className={`${isRestartCooldown ? 'opacity-50' : ''}`}>
              Reiniciar
            </span>
            {isRestartCooldown && (
              <AnimatePresence>
                {showFinishedAnimation ? (
                  <motion.span
                    key="finished"
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.2 }}
                    className="absolute -top-2 -right-2 bg-green-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full"
                  >
                    ✓
                  </motion.span>
                ) : (
                  <motion.span
                    key={countdownKey}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.2 }}
                    className="absolute -top-2 -right-2 bg-yellow-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full"
                  >
                    {cooldownSeconds}
                  </motion.span>
                )}
              </AnimatePresence>
            )}
          </button>

          <button
            onClick={onExit}
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 sm:py-2 sm:px-6 text-sm sm:text-base rounded-lg transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg hover:scale-105 flex-1"
          >
            Sortir
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameLost;
