import React, { useState, useEffect } from 'react';
import { Trophy, Clock, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import InformationModal from './InformationModal';
import { useAuth } from '../context/AuthContext';
import { useDonations } from '../context/DonationsContext';
import { useLocation } from 'react-router-dom';

export const DonationsTable: React.FC = () => {
  const [showTopDonations, setShowTopDonations] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isAdmin } = useAuth();
  const {
    topDonations,
    recentDonations,
    isLoading,
    error,
    isRefreshing,
    loadFullData,
    hasFullData,
  } = useDonations();
  const location = useLocation();
  const isDonatePage = location.pathname === '/donar';

  const INITIAL_DISPLAY_COUNT = 3;
  const MAX_DISPLAY_COUNT = 20;
  const showAll = hasFullData;

  const displayedDonations = showTopDonations
    ? topDonations.slice(0, showAll ? MAX_DISPLAY_COUNT : INITIAL_DISPLAY_COUNT)
    : recentDonations.slice(
        0,
        showAll ? MAX_DISPLAY_COUNT : INITIAL_DISPLAY_COUNT
      );

  const hasMoreToShow = isDonatePage && !showAll;

  const handleShowMore = async () => {
    if (!hasFullData) {
      await loadFullData();
    }
  };

  const handleToggleView = () => {
    setShowTopDonations(!showTopDonations);
  };

  const getTrophyColor = (index: number) => {
    switch (index) {
      case 0:
        return 'text-yellow-500';
      case 1:
        return 'text-gray-400';
      case 2:
        return 'text-orange-500';
      default:
        return 'text-gray-400';
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getRowStyle = (index: number) => {
    if (!showTopDonations) {
      return index % 2 === 0
        ? 'bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-white'
        : 'bg-white dark:bg-gray-800 text-gray-800 dark:text-white';
    }

    switch (index) {
      case 0:
        return 'bg-gradient-to-r from-yellow-200 to-yellow-100 dark:from-yellow-900 dark:to-yellow-800 font-bold text-black dark:text-white';
      case 1:
        return 'bg-gradient-to-r from-gray-200 to-gray-100 dark:from-gray-600 dark:to-gray-500 font-bold text-black dark:text-white';
      case 2:
        return 'bg-gradient-to-r from-orange-200 to-orange-100 dark:from-orange-900 dark:to-orange-800 font-bold text-black dark:text-white';
      default:
        return index % 2 === 0
          ? 'bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-white'
          : 'bg-white dark:bg-gray-800 text-gray-800 dark:text-white';
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden p-8">
        <div className="text-center text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <motion.h2
          key={showTopDonations ? 'top' : 'recent'}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.5 }}
          className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 dark:text-white"
        >
          {showTopDonations ? 'Top Donadors' : 'Últimes Donacions'}
        </motion.h2>
        <div className="flex items-center space-x-2 sm:space-x-4">
          <button
            onClick={handleToggleView}
            className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 py-2 duration-300 transform hover:scale-105"
          >
            {showTopDonations ? (
              <Clock size={20} className="w-5 h-5" />
            ) : (
              <Trophy size={20} className="w-5 h-5" />
            )}
          </button>
          <button
            onClick={() => setShowInfoModal(true)}
            className="flex items-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 py-2 duration-300 transform hover:scale-105"
            aria-label="Informació"
          >
            <Info className="w-5 h-5" />
          </button>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={showTopDonations ? 'top' : 'recent'}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden relative"
        >
          <table className="w-full">
            <thead>
              <tr className="bg-gradient-to-r from-yellow-500 to-red-500 text-white">
                {showTopDonations ? (
                  <>
                    <th className="py-3 px-4 text-left w-1/4">Posició</th>
                    <th className="py-3 px-4 text-center w-1/2">Nom</th>
                    <th className="py-3 px-4 text-right w-1/4">Donat</th>
                  </>
                ) : (
                  <>
                    <th className="py-3 px-4 text-left w-1/4">Data</th>
                    <th className="py-3 px-4 text-center w-1/2">Nom</th>
                    <th className="py-3 px-4 text-right w-1/4">Donat</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {displayedDonations.map((donation, index) => (
                  <motion.tr
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    className={`${getRowStyle(index)} hover:shadow-inner`}
                  >
                    {showTopDonations ? (
                      <>
                        <td className="py-3 px-4 flex items-center">
                          {index < 3 ? (
                            <Trophy
                              className={`${getTrophyColor(index)} mr-2`}
                              size={20}
                            />
                          ) : (
                            <span className="font-semibold">{index + 1}</span>
                          )}
                        </td>
                        <td className="py-3 px-4 font-medium text-center">
                          {donation.name}
                        </td>
                        <td className="py-3 px-4 text-right font-bold">
                          {donation.amount.toFixed(2)}€
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="py-3 px-4 text-sm flex items-center">
                          {formatDate(donation.date)}
                        </td>
                        <td className="py-3 px-4 font-medium text-center">
                          {donation.name}
                        </td>
                        <td className="py-3 px-4 text-right font-bold">
                          {donation.amount.toFixed(2)}€
                        </td>
                      </>
                    )}
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>
          {isRefreshing && (
            <div className="absolute inset-0 bg-black bg-opacity-10 dark:bg-opacity-20 flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>

      {/* Botón "Veure més" */}
      {hasMoreToShow && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="mt-4 flex justify-center"
        >
          <button
            onClick={handleShowMore}
            className="flex items-center mx-auto bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white rounded-full px-3 sm:px-4 py-1.5 sm:py-2 duration-300 text-xs sm:text-sm transform hover:scale-105"
          >
            Veure més
          </button>
        </motion.div>
      )}

      <InformationModal
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
      />
    </>
  );
};
