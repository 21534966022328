import React, { useState } from 'react';
import { Volume2, Lock } from 'lucide-react';

interface RickrollOverlayProps {
  showRickroll: boolean;
  onPasswordCorrect: () => void;
}

const RickrollOverlay: React.FC<RickrollOverlayProps> = ({
  showRickroll,
  onPasswordCorrect,
}) => {
  const [password, setPassword] = useState('');
  const correctPassword = 'a';
  const [isMuted, setIsMuted] = useState(true);

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === correctPassword) {
      onPasswordCorrect();
    }
  };

  const handleVideoCanPlay = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = e.currentTarget;
    video.play();
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div>
      {showRickroll && (
        <div className="fixed inset-0 z-50 bg-black flex flex-col items-center justify-center">
          <video
            autoPlay
            muted={isMuted}
            onCanPlay={handleVideoCanPlay}
            loop
            className="w-full h-full object-cover transform scale-150"
            src="/rickroll.mp4"
          >
            Tu navegador no soporta el elemento de video.
          </video>

          <form
            onSubmit={handlePasswordSubmit}
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-2"
          >
            <button
              onClick={toggleMute}
              className="bg-yellow-500 text-white p-2 rounded-full"
            >
              <Volume2
                className={`w-6 h-6 ${isMuted ? 'opacity-50' : 'opacity-100'}`}
              />
            </button>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contrasenya"
              className="p-2 rounded border border-transparent bg-black text-white text-center"
            />
            <button
              type="submit"
              className="bg-yellow-500 text-white p-2 rounded-full flex items-center justify-center"
            >
              <Lock className="w-6 h-6" />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default RickrollOverlay;
