import { CURRENT_VERSION } from '../appVersions';

type StorageType = 'localStorage' | 'sessionStorage' | 'indexedDB';

interface StorageOptions {
  type?: StorageType;
  expiry?: number;
}

class StorageService {
  private static instance: StorageService;
  private readonly PRESERVED_KEYS = [
    'userId',
    'userToken',
    'donations',
    'accountCreationDate',
    'lastDonationSync',
    'userStats',
    'gameProgress',
  ];

  private constructor() {
    this.initializeStorage();
  }

  static getInstance(): StorageService {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  private initializeStorage(): void {
    const lastVersion = this.get('storageVersion');
    if (lastVersion !== CURRENT_VERSION) {
      this.migrateData(lastVersion, CURRENT_VERSION);
      this.set('storageVersion', CURRENT_VERSION);
    }
  }

  private migrateData(fromVersion: string | null, toVersion: string): void {
    // Implementar migraciones específicas aquí si son necesarias
  }

  set(key: string, value: any, options: StorageOptions = {}): void {
    const { type = 'localStorage', expiry } = options;

    const data = {
      value,
      timestamp: Date.now(),
      expiry: expiry ? Date.now() + expiry : null,
    };

    if (type === 'localStorage' || type === 'sessionStorage') {
      window[type].setItem(key, JSON.stringify(data));
    }
  }

  get(key: string, options: StorageOptions = {}): any {
    const { type = 'localStorage' } = options;

    if (type === 'localStorage' || type === 'sessionStorage') {
      const item = window[type].getItem(key);
      if (!item) return null;

      try {
        const data = JSON.parse(item);

        if (data.expiry && Date.now() > data.expiry) {
          this.remove(key, options);
          return null;
        }

        return data.value;
      } catch {
        return null;
      }
    }

    return null;
  }

  remove(key: string, options: StorageOptions = {}): void {
    const { type = 'localStorage' } = options;

    if (type === 'localStorage' || type === 'sessionStorage') {
      window[type].removeItem(key);
    }
  }

  clear(preserveEssential: boolean = true): void {
    if (preserveEssential) {
      const preserved = this.PRESERVED_KEYS.reduce((acc, key) => {
        const value = this.get(key);
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      localStorage.clear();
      sessionStorage.clear();

      Object.entries(preserved).forEach(([key, value]) => {
        this.set(key, value);
      });
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  hasStorageAccess(): boolean {
    try {
      const testKey = '__storage_test__';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export const storageService = StorageService.getInstance();
