import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { DonationsTable } from './components/DonationsTable';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './context/AuthContext';
import confetti from 'canvas-confetti';
import IdentityModal from './components/IdentityModal';
import BackgroundTexture from './components/BackgroundTexture';
import titleUtils from './utils/titleUtils';
import CookieConsent from './components/CookieConsent';
import { motion } from 'framer-motion';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const DonatePage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [donationAmount, setDonationAmount] = useState('');
  const [donationMessage, setDonationMessage] = useState('');
  const [donorName, setDonorName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState(false);
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasUserName, setHasUserName] = useState(false);

  const MAX_NAME_LENGTH = 20;
  const MAX_MESSAGE_LENGTH = 500;
  const MAX_DONATION_AMOUNT = 999999.99;

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setIsLoaded(true), 100);

    if (user) {
      setDonorName(
        user.user_metadata.full_name || user.email || "Escull un nom d'usuari"
      );
    }
    const savedName = localStorage.getItem('userName');
    setHasUserName(!!savedName);
    if (savedName) {
      setDonorName(savedName);
    }
    const params = new URLSearchParams(location.search);
    if (params.get('ObrirModalUsuari') === 'true') {
      setIsIdentityModalOpen(true);
    }
  }, [user, location.search]);

  const handleDonation = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (
      !donationAmount ||
      isNaN(parseFloat(donationAmount)) ||
      parseFloat(donationAmount) <= 0
    ) {
      setError('Si us plau, introdueix una quantitat vàlida.');
      return;
    }

    if (parseFloat(donationAmount) > MAX_DONATION_AMOUNT) {
      setError(`La quantitat màxima és ${MAX_DONATION_AMOUNT}€.`);
      return;
    }

    if (!user && !donorName?.trim()) {
      setError('Si us plau, introdueix el teu nom.');
      return;
    }

    if (!user && donorName.length > MAX_NAME_LENGTH) {
      setError(`El nom no pot tenir més de ${MAX_NAME_LENGTH} caràcters.`);
      return;
    }

    if (donationMessage.length > MAX_MESSAGE_LENGTH) {
      setError(
        `El missatge no pot tenir més de ${MAX_MESSAGE_LENGTH} caràcters.`
      );
      return;
    }

    setIsSubmitting(true);

    try {
      const localStorageUserId = localStorage.getItem('userId');

      const donationData = {
        amount: parseFloat(donationAmount),
        message: donationMessage,
        name: donorName,
        userId: localStorageUserId,
      };

      const response = await fetch(
        `${
          import.meta.env.VITE_APP_URL
        }/.netlify/functions/create-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(donationData),
        }
      );

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const session = await response.json();

      if (session.id) {
        const stripe = await stripePromise;
        if (stripe) {
          confetti({
            particleCount: 150,
            spread: 100,
            origin: { y: 0.6 },
            scalar: 1.5,
          });

          setTimeout(async () => {
            const { error } = await stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (error) {
              setError(
                error.message || 'Hi ha hagut un error en processar la donació.'
              );
            }
          }, 1500);
        } else {
          setError("No s'ha pogut carregar Stripe.");
        }
      } else {
        throw new Error("No s'ha rebut un ID de sessió vàlid");
      }
    } catch (error) {
      setError(
        error.message || 'Hi ha hagut un error en processar la donació.'
      );
    } finally {
      // Aquí no debes cambiar a false hasta que se complete el proceso
    }
  };

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`${location.pathname}?ObrirModalUsuari=true`);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (newName.length <= MAX_NAME_LENGTH) {
      setDonorName(newName);
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    if (newMessage.length <= MAX_MESSAGE_LENGTH) {
      setDonationMessage(newMessage);
    }
  };

  const formatNumberWithPoints = (number: number | string): string => {
    const numStr = String(number);
    const [integerPart, decimalPart] = numStr.split('.');

    let formattedInteger = '';
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--) {
      formattedInteger = integerPart[i] + formattedInteger;
      count++;
      if (count % 3 === 0 && i !== 0) {
        formattedInteger = '.' + formattedInteger;
      }
    }

    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/,/g, '.');
    value = value.replace(/[^0-9.]/g, '');

    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    const decimalPart = value.split('.')[1];
    if (decimalPart && decimalPart.length > 2) {
      value = value.substring(0, value.length - (decimalPart.length - 2));
    }
    if (parseFloat(value) > MAX_DONATION_AMOUNT) {
      value = MAX_DONATION_AMOUNT.toString();
    }

    if (parseFloat(value) < 0.5 && value !== '') {
      value = '0.5';
    }
    setDonationAmount(value);
  };

  const handleIdentityModalSubmit = (name) => {
    setDonorName(name);
  };

  const handleOpenIdentityModal = () => {
    setIsIdentityModalOpen(true);
    navigate(`${location.pathname}?ObrirModalUsuari=true`);
  };

  const handleCloseIdentityModal = () => {
    setIsIdentityModalOpen(false);

    const params = new URLSearchParams(location.search);

    params.delete('ObrirModalUsuari');

    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { replace: true }
    );
  };

  // Usa el hook para obtener el tamaño de fuente dinámico
  const { titleFontSize } = titleUtils();

  return (
    <div className="relative z-0">
      <BackgroundTexture className="background-texture" zIndex={-1} />
      <div
        className={`min-h-screen flex flex-col transition-colors duration-300`}
      >
        <div className="flex-grow px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
          <div className="max-w-7xl mx-auto">
            <header
              className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-10 opacity-0'
              }`}
            >
              <Link to="/" className="group">
                <div className="icon-bg">
                  <ArrowLeft className="text-white w-1/2 h-1/2" />
                </div>
              </Link>
              <h1
                className={`font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 absolute left-1/2 transform -translate-x-1/2
                  ${titleFontSize}`}
              >
                Donar
              </h1>
              <DarkModeToggle />
            </header>

            <div className="space-y-8">
              <div
                className={`bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-300 ${
                  isLoaded
                    ? 'translate-y-0 opacity-100'
                    : 'translate-y-10 opacity-0'
                }`}
              >
                <div className="p-6">
                  <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 text-gray-800 dark:text-white text-center">
                    La teva donació marca la diferència!
                  </h2>
                  <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
                    Gràcies a la teva generositat, podem continuar oferint
                    minijocs gratuïts i millorant constantment l'experiència de
                    tots els jugadors de la nostra comunitat. Cada donació és
                    una empenta per seguir creant, innovant i oferint moments
                    d'entreteniment a tothom qui vulgui gaudir dels nostres
                    minijocs. Això manté viva la comunitat de{' '}
                    <strong>minijocs.cat</strong>, un espai que és de tots.
                  </p>
                  <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
                    Amb el teu suport, tenim l'oportunitat no només de crear
                    nous minijocs i perfeccionar els actuals, sinó també
                    d'organitzar esdeveniments especials, millorar constantment
                    la plataforma i fer-la accessible i divertida per a tothom,
                    sense barreres. Gràcies a tu, estem construint més que una
                    simple pàgina de minijocs; estem creant un espai on tothom
                    se senti part d'una comunitat catalana vibrant i acollidora.
                  </p>
                  <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
                    La teva donació, gran o petita, té un impacte real i durador
                    que contribueix a mantenir aquesta aventura en marxa i a
                    fer-la créixer. Junts, podem anar més enllà i fer d'aquest
                    lloc un lloc únic per a jugadors de totes les edats. Gràcies
                    per sumar-te a aquesta aventura i per confiar en nosaltres!
                  </p>

                  <IdentityModal
                    isOpen={isIdentityModalOpen}
                    onClose={handleCloseIdentityModal}
                    userName={
                      user?.user_metadata.full_name || user?.email || ''
                    }
                    fromDonatePage={true}
                    onModalSubmit={handleIdentityModalSubmit}
                  />

                  <form onSubmit={handleDonation}>
                    {hasUserName || (
                      <div className="mb-4 p-4 bg-yellow-100 dark:bg-yellow-900 rounded-lg">
                        <div className="text-yellow-800 dark:text-yellow-200 text-center space-y-2">
                          <div>
                            Si ja has donat anteriorment i vols tornar a fer una
                            donació.
                          </div>
                          <div>
                            <button
                              onClick={handleLogin}
                              className="ml-2 underline"
                            >
                              <strong>Inicia sessió</strong>
                            </button>
                            <span>
                              {' '}
                              per aparèixer als Tops amb el teu compte.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {!user && !hasUserName && (
                      <div className="mt-4 relative">
                        <input
                          type="text"
                          value={donorName}
                          onChange={handleNameChange}
                          placeholder="El teu nom"
                          className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                          required
                          maxLength={MAX_NAME_LENGTH}
                        />
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400 text-left">
                          {donorName?.length}/{MAX_NAME_LENGTH} caràcters
                        </p>
                      </div>
                    )}
                    {user && (
                      <div className="mt-4">
                        <div className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg">
                          {donorName}
                        </div>
                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                          Per canviar el nom,{' '}
                          <Link
                            to="/usuari"
                            className="text-blue-500 hover:text-blue-600 underline"
                          >
                            vés al teu perfil d'usuari
                          </Link>
                          .
                        </p>
                      </div>
                    )}
                    <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white text-center mt-6">
                      Tria la quantitat
                    </h3>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
                      {[0.5, 1, 2, 5, 10, 20].map((amount) => (
                        <button
                          key={amount}
                          type="button"
                          onClick={() => setDonationAmount(amount.toString())}
                          className={`py-3 px-6 rounded-lg text-lg font-semibold transform transition-all duration-0 ${
                            donationAmount === amount.toString()
                              ? 'bg-gradient-to-r from-yellow-500 to-red-500 text-white'
                              : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white'
                          } hover:scale-105 ${
                            donationAmount === amount.toString()
                              ? ''
                              : 'hover:bg-gradient-to-r hover:from-yellow-500 hover:to-red-500 hover:text-white'
                          }`}
                        >
                          {amount}€
                        </button>
                      ))}
                    </div>

                    <div className="mt-4 relative">
                      <input
                        type="text"
                        value={donationAmount}
                        onChange={handleAmountChange}
                        placeholder="Altra quantitat"
                        className="w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent pr-8"
                        required
                      />
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-800 dark:text-white text-lg pointer-events-none">
                        €
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <textarea
                        value={donationMessage}
                        onChange={handleMessageChange}
                        placeholder="Deixa un missatge (opcional)"
                        className="mt-4 w-full p-3 border-2 border-yellow-500 rounded-lg bg-white dark:bg-gray-700 text-gray-800 dark:text-white text-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                        rows={3}
                        maxLength={MAX_MESSAGE_LENGTH}
                      />
                      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 text-left">
                        {donationMessage.length}/{MAX_MESSAGE_LENGTH} caràcters
                      </p>
                    </div>
                    <button
                      type="submit"
                      id="donate-form-submit"
                      disabled={isSubmitting}
                      className="mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 flex items-center justify-center text-xl disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        {isSubmitting ? 'Processant...' : 'Fer la Donació'}
                      </motion.div>
                    </button>

                    {error && <p className="text-red-500 mt-2">{error}</p>}
                  </form>
                </div>
              </div>

              <div
                className={`bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-300 ${
                  isLoaded
                    ? 'translate-y-0 opacity-100'
                    : 'translate-y-10 opacity-0'
                }`}
              >
                <div className="p-6">
                  <DonationsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <CookieConsent />
    </div>
  );
};

export default DonatePage;
