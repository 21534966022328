import React from 'react';
import { Sun, Moon } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

const DarkModeToggle: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <button onClick={toggleDarkMode} className="icon-bg">
      <div className="relative w-1/2 h-1/2">
        <Sun
          className={`absolute inset-0 h-full w-full transition-all duration-500 ease-in-out transform ${
            isDarkMode
              ? 'opacity-0 rotate-90 scale-50'
              : 'opacity-100 rotate-0 scale-100'
          } text-white`}
        />
        <Moon
          className={`absolute inset-0 h-full w-full transition-all duration-500 ease-in-out transform ${
            isDarkMode
              ? 'opacity-100 rotate-0 scale-100'
              : 'opacity-0 -rotate-90 scale-50'
          } text-white`}
        />
      </div>
    </button>
  );
};

export default DarkModeToggle;
