import React, { useState, useEffect, useRef, FC } from 'react';
import { Menu, RefreshCcw, ArrowLeft } from 'lucide-react';
import BaseModal from './BaseModal';
import { motion, AnimatePresence } from 'framer-motion';
import GameLost from './GameLost';
import GameRound from './GameRound';
import { Game } from '../utils/gameUtils';

interface GameMenuProps {
  currentRound: number;
  onPause: () => void;
  onResume: () => void;
  onRestart: () => void;
  gameInstructions: string[];
  roundProgress: number;
  onNavigationChange: (allowNavigation: boolean) => void;
  hasPreviousGame: boolean;
  hasNextGame: boolean;
  onPrevious: () => void;
  onNext: () => void;
  isMenuOpen: boolean;
  onMenuToggle: () => void;
  isMenuVisible: boolean;
  hasLost: boolean;
  onExit: () => void;
  gameName: string;
  game: Game;
  resetCounter: number;
  currentGameStats: { customStat: number };
}

interface NavButtonProps {
  onClick: () => void;
  direction: 'left' | 'right';
  ariaLabel: string;
  disabled?: boolean;
}

const NavButton: FC<NavButtonProps> = ({
  onClick,
  direction,
  ariaLabel,
  disabled,
}) => {
  const rotationClass = direction === 'right' ? 'rotate-180' : '';

  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disabled}
      className={`nav-button ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <div className="icon-bg flex items-center justify-center">
        <ArrowLeft
          className={`text-white w-1/2 h-1/2 transition-transform duration-300 ${rotationClass}`}
        />
      </div>
    </button>
  );
};

const GameMenu: FC<GameMenuProps> = ({
  currentRound,
  onPause,
  onResume,
  onRestart,
  gameInstructions,
  roundProgress,
  onNavigationChange,
  hasPreviousGame,
  hasNextGame,
  onPrevious,
  onNext,
  isMenuOpen,
  onMenuToggle,
  isMenuVisible,
  hasLost,
  onExit,
  gameName,
  game,
  resetCounter,
  currentGameStats,
}) => {
  const [animateRound, setAnimateRound] = useState(false);
  const [previousRound, setPreviousRound] = useState(currentRound);
  const [isAnimatingRestart, setIsAnimatingRestart] = useState(false);
  const [restartRotation, setRestartRotation] = useState(0);
  const [isAnimatingRestartOption, setIsAnimatingRestartOption] =
    useState(false);
  const [isAnimatingPauseOption, setIsAnimatingPauseOption] = useState(false);
  const [isAnimatingExitOption, setIsAnimatingExitOption] = useState(false);
  const [isRestartCooldown, setIsRestartCooldown] = useState(false);
  const [cooldownSeconds, setCooldownSeconds] = useState(9);
  const cooldownTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [countdownKey, setCountdownKey] = useState(0);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [showFinishedAnimation, setShowFinishedAnimation] = useState(false);
  const [capturedPreviousBest, setCapturedPreviousBest] = useState<
    number | null
  >(null);
  const [hasGameStarted, setHasGameStarted] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const COOLDOWN_DURATION = 9000; // 9 segundos

  // Obtener stats actuales directamente del objeto game
  const stats = game.getStats();
  const previousBest = stats?.maxRound || 0;

  // Capturar el "récord previo" solo una vez tras perder
  useEffect(() => {
    if (hasLost && capturedPreviousBest === null) {
      setCapturedPreviousBest(previousBest);
    }
  }, [hasLost, previousBest, capturedPreviousBest]);

  const isNewRecord = currentRound > (capturedPreviousBest ?? previousBest);

  useEffect(() => {
    onNavigationChange(isMenuOpen);
  }, [isMenuOpen, onNavigationChange]);

  // Pequeña animación si sube la ronda
  useEffect(() => {
    if (currentRound !== previousRound) {
      setAnimateRound(true);
      setTimeout(() => setAnimateRound(false), 300);
      setPreviousRound(currentRound);
    }
  }, [currentRound, previousRound]);

  const handleExitClick = () => {
    setIsAnimatingExitOption(true);
    setTimeout(() => {
      onExit();
    }, 200);
  };

  const startCooldown = () => {
    setIsRestartCooldown(true);
    setCooldownSeconds(9);
    setIsCountingDown(true);
    setShowFinishedAnimation(false);
    setCountdownKey((prevKey) => prevKey + 1);

    const startTime = Date.now();
    const updateCooldown = () => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.ceil((COOLDOWN_DURATION - elapsed) / 1000);

      if (remaining <= 0) {
        setIsRestartCooldown(false);
        setIsCountingDown(false);
        setShowFinishedAnimation(true);
        if (cooldownTimerRef.current) {
          clearInterval(cooldownTimerRef.current);
        }
      } else {
        setCooldownSeconds(remaining);
      }
    };
    cooldownTimerRef.current = setInterval(updateCooldown, 100);
  };

  const handleRestart = () => {
    if (isRestartCooldown) return;

    setIsAnimatingRestart(true);
    const nextRotation = restartRotation - 360;
    setRestartRotation(nextRotation);

    startCooldown();

    setTimeout(() => {
      setIsAnimatingRestart(false);
      onRestart();
    }, 200);
  };

  const handleMenuToggleLocal = () => {
    onMenuToggle();
  };

  const handlePauseClick = () => {
    setIsAnimatingPauseOption(true);
    setTimeout(() => {
      onPause();
      handleMenuToggleLocal();
    }, 200);
  };

  const handleRestartMenuClick = () => {
    if (isRestartCooldown) return;
    setIsAnimatingRestartOption(true);
    startCooldown();
    setTimeout(() => {
      onMenuToggle(); // Cierra el menú
      onRestart(); // Reinicia el juego
    }, 200);
  };

  const handlePlayClick = () => {
    // La primera vez pasamos de "Iniciar" a "Jugar"
    // si ya ha empezado, sólo "Continuar"
    if (!hasGameStarted) {
      setHasGameStarted(true);
      onResume();
    } else {
      onResume();
    }
    handleMenuToggleLocal(); // Cerrar menú
  };

  const handleMenuClose = () => {
    if (hasLost) {
      if (isRestartCooldown) return;
      startCooldown();
      onRestart();
    } else {
      onMenuToggle();
    }
  };

  useEffect(() => {
    return () => {
      if (cooldownTimerRef.current) {
        clearInterval(cooldownTimerRef.current);
      }
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full flex justify-center z-20">
      <div
        ref={menuRef}
        className={`relative bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white py-2 px-3 rounded-b-md shadow-md inline-flex items-center game-menu-container transition-transform duration-500 transform ${
          isMenuVisible ? 'active' : ''
        }`}
      >
        {/* Botón del menú */}
        <div className="rounded-md flex items-center justify-center">
          <motion.button
            onClick={handleMenuToggleLocal}
            className="text-gray-500 dark:text-gray-400 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors active:scale-95 transition-transform duration-100"
            aria-label="Obrir el menú"
            whileTap={{ scale: 0.9 }}
          >
            <Menu className="w-5 h-5 transition-transform duration-500" />
          </motion.button>
        </div>

        {/* Barra de ronda con progreso */}
        <GameRound
          currentRound={currentRound}
          roundProgress={roundProgress}
          hasLost={hasLost}
          restartCount={resetCounter}
        />

        {/* Botón de reinicio rápido */}
        <div className="rounded-md flex items-center justify-center relative">
          <motion.button
            onClick={handleRestart}
            disabled={isRestartCooldown}
            className={`text-gray-500 dark:text-gray-400 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors relative ${
              isRestartCooldown
                ? 'cursor-not-allowed active:scale-100'
                : 'active:scale-95'
            }`}
            aria-label="Reiniciar el joc"
            whileTap={{ scale: 0.9 }}
          >
            <RefreshCcw
              className={`w-5 h-5 transition-transform duration-500 ${
                isRestartCooldown ? 'opacity-50' : ''
              }`}
              style={{ transform: `rotate(${restartRotation}deg)` }}
            />
            {isRestartCooldown && (
              <AnimatePresence>
                {showFinishedAnimation ? (
                  <motion.span
                    key="finished"
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.2 }}
                    className="absolute -top-1 -right-1 bg-green-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full"
                  >
                    ✓
                  </motion.span>
                ) : (
                  <motion.span
                    key={countdownKey}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.2 }}
                    className="absolute -top-1 -right-1 bg-yellow-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full"
                  >
                    {cooldownSeconds}
                  </motion.span>
                )}
              </AnimatePresence>
            )}
          </motion.button>
        </div>

        {/* Modal del menú principal */}
        <BaseModal isOpen={isMenuOpen} onClose={handleMenuClose} maxWidth="md">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            {hasLost ? (
              <GameLost
                currentRound={currentRound}
                onRestart={handleRestart}
                onExit={handleExitClick}
                isRestartCooldown={isRestartCooldown}
                cooldownSeconds={cooldownSeconds}
                showFinishedAnimation={showFinishedAnimation}
                countdownKey={countdownKey}
                isNewRecord={isNewRecord}
                previousBest={previousBest}
                stats={stats}
                gameInstructions={gameInstructions}
                gameName={gameName}
                customStatLabel={game.info.customStatLabel}
                customStatLabelSingular={game.info.customStatLabelSingular}
                game={game}
                currentGameStats={currentGameStats}
              />
            ) : (
              <div className="space-y-6">
                <div className="text-gray-600 dark:text-gray-300">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={gameName}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <h3 className="text-lg font-semibold mb-4 text-center">
                        {`Com jugar a ${gameName}?`}
                      </h3>
                      {gameInstructions.map((paragraph, index) => (
                        <p key={index} className="mb-1 text-center">
                          {paragraph}
                        </p>
                      ))}
                    </motion.div>
                  </AnimatePresence>
                  <div className="bg-gray-100 dark:bg-gray-700 p-4 mt-4 rounded-lg flex items-center justify-between">
                    <NavButton
                      onClick={onPrevious}
                      direction="left"
                      ariaLabel="Joc anterior"
                      disabled={!hasPreviousGame}
                    />
                    <div className="text-center flex-1 px-4 max-w-[300px] md:max-w-[400px]">
                      <h4 className="text-sm font-medium mb-2">
                        Descobreix més minijocs!
                      </h4>
                      <p className="text-sm hidden md:block">
                        Utilitza les fletxes laterals per explorar més minijocs.
                      </p>
                      <p className="text-sm md:hidden">
                        Llisca el dit per la pantalla per explorar més minijocs.
                      </p>
                    </div>
                    <NavButton
                      onClick={onNext}
                      direction="right"
                      ariaLabel="Següent joc"
                      disabled={!hasNextGame}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex space-x-3">
                    {/* Botón de Jugar o Continuar */}
                    <motion.button
                      onClick={handlePlayClick}
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 sm:py-2 sm:px-6 text-sm sm:text-base rounded-lg transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg hover:scale-105 flex-1"
                      whileTap={{ scale: 0.9 }}
                    >
                      {hasGameStarted ? 'Continuar' : 'Jugar'}
                    </motion.button>
                    {/* Botón de Salir */}
                    <motion.button
                      onClick={handleExitClick}
                      className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 sm:py-2 sm:px-6 text-sm sm:text-base rounded-lg transition transform duration-300 flex items-center justify-center shadow-md hover:shadow-lg hover:scale-105 flex-1"
                      whileTap={{ scale: 0.9 }}
                    >
                      Sortir
                    </motion.button>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </BaseModal>
      </div>
    </div>
  );
};

export default GameMenu;
