import React, {
  useState,
  useEffect,
  Suspense,
  useRef,
  useCallback,
} from 'react';
import {
  useParams,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { ArrowLeft, Maximize, Minimize } from 'lucide-react';
import DarkModeToggle from './components/DarkModeToggle';
import Footer from './components/Footer';
import { getGameByPath, getNextGame, getPreviousGame } from './games/registry';
import BackgroundTexture from './components/BackgroundTexture';
import GameMenu from './components/GameMenu';
import CookieConsent from './components/CookieConsent';
import titleUtils from './utils/titleUtils';
import { motion } from 'framer-motion';

interface AnimationDelays {
  windowTransition: number;
}

const GamePage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isGameReady, setIsGameReady] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [hoverSide, setHoverSide] = useState<'left' | 'right' | 'none'>('none');
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  const [currentRound, setCurrentRound] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [roundProgress, setRoundProgress] = useState(0);
  const [isNavigationAllowed, setIsNavigationAllowed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [animationDelays, setAnimationDelays] = useState<AnimationDelays>({
    windowTransition: 500,
  });
  const [hasLost, setHasLost] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [resetCounter, setResetCounter] = useState(0);

  const [currentGameStats, setCurrentGameStats] = useState<{
    customStat: number;
  }>({ customStat: 0 });

  const { path } = useParams<{ path: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const game = path ? getGameByPath(`/${path}`) : null;
  const GameComponent = game?.component;

  const nextGame = game ? getNextGame(game.info.id) : null;
  const previousGame = game ? getPreviousGame(game.info.id) : null;
  const isFirstLoad = useRef(true);
  const gameContainerRef = useRef<HTMLDivElement>(null);
  const navigationDirection = useRef<'left' | 'right'>('right');
  const { titleFontSize } = titleUtils();
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const fullscreenRef = useRef<HTMLDivElement>(null);
  const expandAnimationRef = useRef<ReturnType<
    typeof requestAnimationFrame
  > | null>(null);

  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight);
    setIsMobile(window.innerWidth <= 640);
  }, []);

  const handleGlobalMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isMobile) return;

      const threshold = 0.3;
      const leftThreshold = window.innerWidth * threshold;
      const rightThreshold = window.innerWidth * (1 - threshold);

      if (e.clientX < leftThreshold) {
        setHoverSide('left');
      } else if (e.clientX > rightThreshold) {
        setHoverSide('right');
      } else {
        setHoverSide('none');
      }
    },
    [isMobile]
  );

  const handleComponentLoad = useCallback(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsGameReady(true);
    }, 700);
    isFirstLoad.current = false;
  }, []);

  useEffect(() => {
    setIsLoaded(false);
    setIsGameReady(false);
    setIsTitleVisible(!isExpanded);
    setCurrentRound(1);
    setIsPaused(false);
    setRoundProgress(0);
    setIsNavigationAllowed(true);
    setHasLost(false);

    if (GameComponent) {
      handleComponentLoad();
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleGlobalMouseMove);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, [
    path,
    GameComponent,
    handleComponentLoad,
    handleGlobalMouseMove,
    handleResize,
    isExpanded,
  ]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!touchStart || hasLost) return;

    const touchEnd = e.changedTouches[0].clientX;
    const diff = touchStart - touchEnd;

    if (isMenuOpen) {
      if (Math.abs(diff) > 50) {
        if (diff > 0 && nextGame && isNavigationAllowed) {
          navigationDirection.current = isMobile ? 'left' : 'right';
          handleNavigation(nextGame.info.path);
        } else if (diff < 0 && previousGame && isNavigationAllowed) {
          navigationDirection.current = isMobile ? 'right' : 'left';
          handleNavigation(previousGame.info.path);
        }
      }
    }

    setTouchStart(null);
  };

  const handleNavigation = useCallback(
    (newPath: string) => {
      if (!isNavigationAllowed) return;
      setIsTitleVisible(false);
      setIsGameReady(false);

      const direction: 'left' | 'right' = navigationDirection.current;

      if (gameContainerRef.current) {
        gameContainerRef.current.style.transition = `transform ${
          animationDelays.windowTransition / 1000
        }s ease-in-out, opacity ${
          animationDelays.windowTransition / 1000
        }s ease-in-out`;
        gameContainerRef.current.style.transform =
          direction === 'left' ? 'translateX(-100%)' : 'translateX(100%)';
        gameContainerRef.current.style.opacity = '0';
      }

      setTimeout(() => {
        navigate(newPath);
        if (gameContainerRef.current) {
          gameContainerRef.current.style.transition = 'none';
          gameContainerRef.current.style.transform =
            direction === 'left' ? 'translateX(100%)' : 'translateX(-100%)';
          gameContainerRef.current.style.opacity = '0';

          requestAnimationFrame(() => {
            gameContainerRef.current!.style.transition = `transform ${
              animationDelays.windowTransition / 1000
            }s ease-in-out, opacity ${
              animationDelays.windowTransition / 1000
            }s ease-in-out`;
            gameContainerRef.current!.style.transform = 'translateX(0)';
            gameContainerRef.current!.style.opacity = '1';
          });
        }
        setTimeout(() => {
          setIsTitleVisible(true);
        }, animationDelays.windowTransition);
      }, animationDelays.windowTransition);
    },
    [navigate, isNavigationAllowed, animationDelays]
  );

  const handleNextRound = () => {
    if (!isMenuOpen) {
      setCurrentRound((prevRound) => prevRound + 1);
    }
  };

  const handleRestart = () => {
    setCurrentRound(1);
    setRoundProgress(0);
    setResetCounter((prev) => prev + 1);
    setHasLost(false);
    setIsMenuOpen(false);
  };

  const handleGameStart = () => {};

  const handleGameEnd = () => {};

  const handleTotalClicks = (clicks: number) => {};

  const handlePause = () => {
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  const handleUpdateProgress = (progress: number) => {
    setRoundProgress(progress);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handlePreviousGame = () => {
    if (previousGame) {
      navigationDirection.current = 'left';
      handleNavigation(previousGame.info.path);
    }
  };

  const handleNextGame = () => {
    if (nextGame) {
      navigationDirection.current = 'right';
      handleNavigation(nextGame.info.path);
    }
  };

  const handleGameLost = (lost: boolean, stats?: { customStat: number }) => {
    setHasLost(lost);
    if (lost && game) {
      const existingStats = game.getStats();
      const updatedStats = { ...existingStats };
      updatedStats.gamesPlayed = (updatedStats.gamesPlayed || 0) + 1;
      if (currentRound > (updatedStats.maxRound || 0)) {
        updatedStats.maxRound = currentRound;
      }
      if (stats && stats.customStat) {
        updatedStats.customStat =
          (updatedStats.customStat || 0) + stats.customStat;
      }
      if (game.saveStats) {
        game.saveStats(updatedStats);
      }

      setCurrentGameStats(updatedStats);
    }
  };

  useEffect(() => {
    setIsPaused(isMenuOpen);
  }, [isMenuOpen]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (fullscreenRef.current) {
        fullscreenRef.current
          .requestFullscreen()
          .then(() => setIsFullScreen(true))
          .catch((err) =>
            console.error('Error attempting to enable fullscreen:', err)
          );
      }
    } else if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => setIsFullScreen(false))
        .catch((err) =>
          console.error('Error attempting to exit fullscreen:', err)
        );
    }
  };

  const toggleExpand = () => {
    const wasExpanded = isExpanded;
    setIsExpanded(!wasExpanded);
    setIsTitleVisible(!wasExpanded);
    setIsMenuOpen(false);
    if (headerRef.current && footerRef.current && gameContainerRef.current) {
      const transitionTime = `${animationDelays.windowTransition / 1000}s`;

      headerRef.current.style.transition = `opacity ${transitionTime}, transform ${transitionTime}`;
      footerRef.current.style.transition = `opacity ${transitionTime}, transform ${transitionTime}`;
      gameContainerRef.current.style.transition = `transform ${transitionTime} cubic-bezier(0.4, 0, 0.2, 1), height ${transitionTime} cubic-bezier(0.4, 0, 0.2, 1)`;
      const frame = () => {
        if (gameContainerRef.current) {
          const headerTransform = wasExpanded
            ? 'translateY(0)'
            : 'translateY(-100px)';
          const headerOpacity = wasExpanded ? '1' : '0';
          const footerTransform = wasExpanded
            ? 'translateY(0)'
            : 'translateY(100px)';
          const footerOpacity = wasExpanded ? '1' : '0';
          const gameTransform = wasExpanded ? 'scale(1)' : 'scale(1.0)';
          const gameHeight = wasExpanded ? `${windowHeight}px` : `100vh`;

          headerRef.current.style.transform = headerTransform;
          headerRef.current.style.opacity = headerOpacity;
          footerRef.current.style.transform = footerTransform;
          footerRef.current.style.opacity = footerOpacity;
          gameContainerRef.current.style.transform = gameTransform;
          gameContainerRef.current.style.height = gameHeight;
        }
      };

      if (expandAnimationRef.current) {
        cancelAnimationFrame(expandAnimationRef.current);
      }
      expandAnimationRef.current = requestAnimationFrame(frame);
    }
  };

  const navigateBack = () => {
    navigate('/');
  };

  return (
    <div className="relative z-0" ref={fullscreenRef}>
      <BackgroundTexture className="background-texture" zIndex={-1} />
      <div className="h-screen flex flex-col">
        <div
          className={`flex-grow px-2 sm:px-6 lg:px-8 py-2 sm:py-8 ${
            isMobile ? 'overflow-auto' : 'overflow-hidden'
          }`}
        >
          <div
            className={`h-full flex flex-col ${
              isExpanded ? '' : 'max-w-7xl mx-auto'
            }`}
          >
            <header
              ref={headerRef}
              className={`flex justify-between items-center mb-2 sm:mb-8 transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-10 opacity-0'
              } ${isExpanded ? 'hidden' : ''}`}
            >
              <Link to="/" className="group">
                <div className="icon-bg">
                  <ArrowLeft className="text-white w-1/2 h-1/2" />
                </div>
              </Link>
              <h1
                className={`font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 absolute left-1/2 transform -translate-x-1/2 transition-all duration-300 ${
                  isTitleVisible
                    ? 'opacity-100 scale-100'
                    : 'opacity-0 scale-90'
                } ${titleFontSize}`}
              >
                {game?.info.title}
              </h1>
              <DarkModeToggle />
            </header>

            <div
              ref={gameContainerRef}
              className={`relative bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out transform ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              } ${isGameReady ? 'fade-in' : ''}`}
              style={{
                height: isExpanded ? '100vh' : `${windowHeight}px`,
                minHeight: isExpanded ? undefined : '400px',
                transition:
                  'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), height 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                transformOrigin: 'center',
              }}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              {/* Botón de Atrás */}
              {isExpanded && (
                <motion.div
                  className="absolute top-0 left-0 z-50 pointer-events-none"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6, duration: 0.3 }}
                >
                  <div className="relative pointer-events-auto">
                    <div className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white py-2 px-3 rounded-b-md shadow-md inline-flex items-center game-menu-container transition-transform duration-500 transform -ml-1">
                      <motion.button
                        onClick={navigateBack}
                        className="text-gray-500 dark:text-gray-400 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors active:scale-95 transition-transform duration-100"
                        aria-label="Atrás"
                        whileTap={{ scale: 0.9 }}
                      >
                        <ArrowLeft className="w-5 h-5" />
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              )}

              {/* Botón de Ampliar */}
              {!isMobile && (
                <motion.div
                  className="absolute top-0 right-0 z-50 pointer-events-none"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6, duration: 0.3 }}
                >
                  <div className="relative pointer-events-auto">
                    <div className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white py-2 px-3 rounded-b-md shadow-md inline-flex items-center game-menu-container transition-transform duration-500 transform -mr-1">
                      <motion.button
                        onClick={toggleExpand}
                        className="text-gray-500 dark:text-gray-400 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors active:scale-95 transition-transform duration-100"
                        whileTap={{ scale: 0.9 }}
                      >
                        {isExpanded ? (
                          <Minimize className="w-5 h-5" />
                        ) : (
                          <Maximize className="w-5 h-5" />
                        )}
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              )}

              <motion.div
                className="z-50 pointer-events-none"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.3 }}
              >
                <div className="pointer-events-auto">
                  <GameMenu
                    currentRound={currentRound}
                    onPause={handlePause}
                    onResume={handleResume}
                    onRestart={handleRestart}
                    gameInstructions={game.info.instructions}
                    roundProgress={roundProgress}
                    onNavigationChange={setIsNavigationAllowed}
                    hasPreviousGame={!!previousGame}
                    hasNextGame={!!nextGame}
                    onPrevious={handlePreviousGame}
                    onNext={handleNextGame}
                    isMenuOpen={isMenuOpen}
                    onMenuToggle={handleMenuToggle}
                    isMenuVisible={isMenuOpen}
                    hasLost={hasLost}
                    onExit={() => navigate('/')}
                    gameName={game.info.title}
                    game={game}
                    resetCounter={resetCounter}
                    currentGameStats={currentGameStats}
                    onToggleExpand={toggleExpand}
                    isExpanded={isExpanded}
                    onNavigateBack={navigateBack}
                  />
                </div>
              </motion.div>

              {!isGameReady ? (
                <div className="flex items-center justify-center h-full">
                  <div className="animate-spin rounded-full h-32 w-32 border-b-8 border-yellow-500"></div>
                </div>
              ) : (
                <motion.div
                  className="w-full h-full flex items-center justify-center"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.45, duration: 0.3 }}
                >
                  {isGameReady && GameComponent && (
                    <Suspense
                      fallback={
                        <div className="animate-spin rounded-full h-32 w-32 border-b-8 border-yellow-500"></div>
                      }
                    >
                      <GameComponent
                        handleNextRound={handleNextRound}
                        handleGameStart={handleGameStart}
                        handleGameEnd={handleGameEnd}
                        handleTotalClicks={handleTotalClicks}
                        isPaused={isPaused}
                        updateProgress={handleUpdateProgress}
                        reset={resetCounter}
                        key={resetCounter}
                        onMenuToggle={handleMenuToggle}
                        onGameLost={handleGameLost}
                        isMenuOpen={isMenuOpen}
                      />
                    </Suspense>
                  )}
                </motion.div>
              )}
            </div>
          </div>
        </div>
        <div
          ref={footerRef}
          className={`transition-all duration-300 ${
            isExpanded || isMobile
              ? 'opacity-0 transform translate-y-10 hidden'
              : 'opacity-100 transform translate-y-0'
          }`}
        >
          {!isExpanded && !isMobile && <Footer />}
        </div>
      </div>
      <CookieConsent />
    </div>
  );
};

export default GamePage;
