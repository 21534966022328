export const CURRENT_VERSION = '01';

export const VERSIONS = [
  {
    version: CURRENT_VERSION,
    changes: [
      'Nova opció per buscar minijocs per noms, descripcions, categories o etiquetes.',
      'Funcionalitat per veure els minijocs més jugats i amb millors rondes.',
      'Afegida opció de minijoc aleatori amb el dau.',
      'Optimització de la interfície per a dispositius mòbils.',
    ],
  },
];
