import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { storageService } from '../services/storageService';

interface CookiePreferences {
  essential: boolean;
  analytics: boolean;
  functional: boolean;
}

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const hasConsent = storageService.get('cookieConsent');
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    if (isSaving) return;
    const newPreferences = {
      essential: true,
      analytics: true,
      functional: true,
    };
    savePreferences(newPreferences);
  };

  const handleRejectAll = () => {
    if (isSaving) return;
    const newPreferences = {
      essential: true,
      analytics: false,
      functional: false,
    };
    savePreferences(newPreferences);
  };

  const savePreferences = async (prefs: CookiePreferences) => {
    try {
      setIsSaving(true);
      setIsClosing(true);

      // Guardar las preferencias primero en localStorage a través de nuestro service
      storageService.set('cookieConsent', {
        preferences: prefs,
        timestamp: Date.now(),
      });

      // Habilitar o deshabilitar Google Analytics según las preferencias
      window['ga-disable-G-76NZ0KJK8Z'] = !prefs.analytics;

      // Notificar el cambio (disparamos un evento global)
      window.dispatchEvent(
        new CustomEvent('cookiePreferencesChanged', {
          detail: prefs,
        })
      );

      // Solo esperamos un momento para la animación y ocultamos el banner
      await new Promise((resolve) => setTimeout(resolve, 300));
      setIsVisible(false);
      setIsClosing(false);
      setIsSaving(false);
    } catch (error) {
      console.error('Error al guardar las preferencias de cookies:', error);
      setIsVisible(false);
      setIsClosing(false);
      setIsSaving(false);
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="cookie-consent-modal"
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg p-4 z-50"
        >
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
              <div className="w-full sm:w-auto sm:flex-1">
                <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 mb-2">
                  Política de Cookies
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Aquest lloc web utilitza cookies essencials per al seu
                  funcionament bàsic. També fem servir Google Analytics per
                  entendre com navegues i així millorar el lloc. Les cookies
                  essencials són imprescindibles i no es poden desactivar. Si
                  acceptes, ens ajudaràs amb estadístiques anònimes per oferir
                  una millor experiència.
                </p>
              </div>
              <div className="flex flex-col gap-3 w-full sm:w-auto">
                <button
                  onClick={handleAcceptAll}
                  disabled={isSaving}
                  className={`w-full px-6 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 rounded-md transition-all duration-300 ${
                    isSaving
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:scale-105'
                  }`}
                >
                  {'Acceptar'}
                </button>
                <button
                  onClick={handleRejectAll}
                  disabled={isSaving}
                  className={`w-full px-6 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-md transition-all duration-300 ${
                    isSaving
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:scale-105'
                  }`}
                >
                  {'Només Essencials'}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieConsent;
