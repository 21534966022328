// src/utils/levelUtils.ts
import { useState, useEffect, useMemo } from 'react';
import { useAnimation, AnimationControls } from 'framer-motion';
import { getGames } from '../games/registry';

interface LevelInfo {
  level: number;
  progress: number;
  gamesInCurrentLevel: number;
  gamesRequiredForNextLevel: number;
}

// === Configuración de los pesos de las estadísticas ===
const statsWeights = {
  gamesPlayed: 2, // Multiplicador para partidas jugadas
  maxRound: 4, // Multiplicador para la mejor ronda
  customStat: 1, // Multiplicador para la estadística personalizada
};
// ======================================================

// Función para calcular el total de puntos de experiencia (XP)
function calculateTotalXP(): number {
  const games = getGames();
  let totalXP = 0;

  games.forEach((game) => {
    const stats = game.getStats();
    if (stats) {
      // Partidas jugadas con el multiplicador configurado
      totalXP += stats.gamesPlayed * statsWeights.gamesPlayed;

      // Mejor ronda con el multiplicador configurado
      totalXP += (stats.maxRound || 0) * statsWeights.maxRound;

      // Estadística personalizada con el multiplicador configurado
      totalXP += (stats.customStat || 0) * statsWeights.customStat;
    }
  });

  return totalXP;
}

// Función para obtener la información del nivel basado en el total de partidas jugadas
export function getLevelInfo(
  totalGamesPlayed: number,
  disableAnimation: boolean
): LevelInfo {
  const totalXP = calculateTotalXP();
  let level = 1;
  let xpRequiredForNextLevel = 10; // Nivel 1 requiere 10 XP
  let accumulatedXP = 0;

  // Calcular el nivel actual basado en XP total, limitado a 100
  while (accumulatedXP + xpRequiredForNextLevel <= totalXP && level < 100) {
    accumulatedXP += xpRequiredForNextLevel;
    level++;
    xpRequiredForNextLevel += 1; // Cada nivel requiere 1 XP más
  }

  // Si el nivel alcanzado es 100, no hay progreso adicional
  if (level === 100) {
    return {
      level,
      progress: 0,
      gamesInCurrentLevel: 0,
      gamesRequiredForNextLevel: 0,
    };
  }

  const xpInCurrentLevel = totalXP - accumulatedXP;
  const progress = disableAnimation
    ? Math.max((xpInCurrentLevel / xpRequiredForNextLevel) * 100, 0.1)
    : Math.max((xpInCurrentLevel / xpRequiredForNextLevel) * 100, 0.1);

  return {
    level,
    progress,
    gamesInCurrentLevel: xpInCurrentLevel,
    gamesRequiredForNextLevel: xpRequiredForNextLevel,
  };
}

interface UsePlayerLevelProps {
  totalGamesPlayed: number;
  disableAnimation?: boolean;
}

interface UsePlayerLevelResult {
  level: number;
  progress: number;
  animatedLevel: number;
  percentageToNextLevel: number;
  ringControls: AnimationControls;
  playerIconControls: AnimationControls;
  progressControls: AnimationControls;
}

// Función para calcular la duración de la animación basada en el porcentaje de progreso
function calculateDuration(progress: number): number {
  const minDuration = 0.5; // Duración mínima en segundos
  const maxDuration = 2; // Duración máxima en segundos
  // Si el progreso es 0.1 (mínimo), usar la duración mínima
  if (progress <= 0.1) return minDuration;
  return minDuration + (progress / 100) * (maxDuration - minDuration);
}

// Hook personalizado para gestionar el nivel del jugador y las animaciones
export const usePlayerLevel = ({
  totalGamesPlayed,
  disableAnimation = false,
}: UsePlayerLevelProps): UsePlayerLevelResult => {
  // 1. Primero los controles de animación
  const progressControls = useAnimation();
  const ringControls = useAnimation();
  const playerIconControls = useAnimation();

  // 2. Obtener la información del nivel
  const {
    level,
    progress: rawProgress,
    gamesInCurrentLevel,
    gamesRequiredForNextLevel,
  } = getLevelInfo(totalGamesPlayed, disableAnimation);

  // 3. Estado para el nivel animado
  const [animatedLevel, setAnimatedLevel] = useState(level);

  // 4. Constantes y cálculos
  const circumference = 2 * Math.PI * 70;
  const progressMin = 0.1;
  const progressMax = 100;
  // Usar rawProgress directamente, solo aplicar el mínimo si es 0
  const adjustedProgress = rawProgress === 0 ? progressMin : rawProgress;

  // 5. Efecto para las animaciones
  useEffect(() => {
    const animate = async () => {
      const newOffset =
        circumference - (adjustedProgress / 100) * circumference;

      if (disableAnimation) {
        // Establecer el valor directamente sin animación
        await progressControls.set({
          strokeDashoffset: newOffset,
        });
      } else {
        // Animar al valor final
        await progressControls.start({
          strokeDashoffset: newOffset,
          transition: {
            duration: calculateDuration(adjustedProgress),
            ease: 'easeOut',
          },
        });
      }

      setAnimatedLevel(level);
    };

    animate();
  }, [
    level,
    adjustedProgress,
    circumference,
    progressControls,
    disableAnimation,
  ]);

  return {
    level,
    progress: adjustedProgress,
    animatedLevel,
    percentageToNextLevel: Math.round(
      (gamesInCurrentLevel / gamesRequiredForNextLevel) * 100
    ),
    ringControls,
    playerIconControls,
    progressControls,
  };
};
