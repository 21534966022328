// src/games/ClickGame/gameInfo.ts
import { MousePointer } from 'lucide-react';

export const GAME_INFO: GameInfo = {
  id: 1,
  title: 'Clica',
  description: 'Desafia els teus reflexos!',
  instructions: [
    'Completa una ronda amb 4 clics, i cada 2 rondes els cercles apareixeran més ràpid.',
    'Cada 4 rondes apareixerà un cercle nou, fins a un màxim de 3!',
  ],
  icon: MousePointer,
  path: '/clica',
  category: 'Habilitat',
  theme: {
    gradient: {
      from: 'yellow-500',
      to: 'red-500',
    },
    pattern: {
      type: 'dots',
      color: 'currentColor',
      opacity: 0,
      size: 4,
      spacing: 16,
    },
  },
  localStorageKey: 'clickGameStats',
  customStatLabel: 'Clics Totals',
  customStatLabelSingular: 'Clics',
};
