import { CURRENT_VERSION } from '../appVersions';

export const checkAndClearCache = async () => {
  try {
    // Verificar si la versión del bundle ha cambiado
    const lastCacheVersion = localStorage.getItem('lastCacheVersion');
    const needsCacheClean = lastCacheVersion !== CURRENT_VERSION;

    if (!needsCacheClean) {
      return false;
    }

    // Forzar la recarga de la caché del navegador: eliminar Service Workers
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
    }

    // Limpiar cachés
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    }

    localStorage.setItem('lastCacheVersion', CURRENT_VERSION);

    // Solo recargamos si realmente se limpió alguna caché
    if (
      ('serviceWorker' in navigator &&
        (await navigator.serviceWorker
          .getRegistrations()
          .then((regs) => regs.length > 0))) ||
      ('caches' in window &&
        (await caches.keys().then((keys) => keys.length > 0)))
    ) {
      window.location.reload();
      return true;
    }

    return false;
  } catch (error) {
    console.error('Error al limpiar la caché:', error);
    return false;
  }
};
