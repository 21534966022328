import CryptoJS from 'crypto-js';
import { getGames } from '../games/registry';
import { GameStats } from '../games/game';

const ENCRYPTION_KEY = 'minijocs-stats-v2';

interface AllStats {
  [gameName: string]: GameStats | undefined;
  timestamp: number;
  accountCreationDate?: string;
}

const getStatsFromLocalStorage = (): AllStats => {
  const stats: AllStats = { timestamp: Date.now() };
  const games = getGames();

  games.forEach((game) => {
    const localStorageKey = game.localStorageKey;
    const storedStats = localStorage.getItem(localStorageKey);
    if (storedStats) {
      stats[localStorageKey] = JSON.parse(storedStats);
    }
  });

  const accountCreationDate = localStorage.getItem('accountCreationDate');
  if (accountCreationDate) {
    stats.accountCreationDate = accountCreationDate;
  }

  return stats;
};

export const encryptStats = (userId: string): string => {
  const stats = getStatsFromLocalStorage();
  const statsString = JSON.stringify(stats);
  return CryptoJS.AES.encrypt(statsString, ENCRYPTION_KEY + userId).toString();
};

export const decryptStats = (
  encryptedStats: string,
  userId: string
): AllStats | null => {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      encryptedStats,
      ENCRYPTION_KEY + userId
    );
    const statsString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(statsString);
  } catch (error) {
    console.error('Error decrypting stats:', error);
    return null;
  }
};

export const importStats = (
  encryptedStats: string,
  userId: string
): boolean => {
  try {
    const stats = decryptStats(encryptedStats, userId);
    if (!stats || !stats.timestamp) return false;

    const games = getGames();

    games.forEach((game) => {
      const localStorageKey = game.localStorageKey;
      const importedGameStats = stats[localStorageKey];
      if (importedGameStats) {
        // Siempre actualiza las estadísticas
        localStorage.setItem(
          localStorageKey,
          JSON.stringify({
            ...importedGameStats,
            timestamp: Date.now(),
          })
        );
      }
    });

    if (stats.accountCreationDate) {
      localStorage.setItem('accountCreationDate', stats.accountCreationDate);
    }

    return true;
  } catch (error) {
    console.error('Error importing stats:', error);
    return false;
  }
};
