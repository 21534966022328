import React from 'react';
import { Game } from '../games/game';

import { GAME_INFO as clickGameInfo } from './ClickGame/gameInfo';
import { GAME_INFO as twoGameInfo } from './ColorGame/gameInfo';

const ClickGame = React.lazy(() => import('./ClickGame'));
const TwoGame = React.lazy(() => import('./ColorGame'));

const getGameStats = <T>(
  localStorageKey: string,
  gameName: string
): T & { gameName: string } => {
  const saved = localStorage.getItem(localStorageKey);
  return saved
    ? { ...JSON.parse(saved), gameName }
    : ({
        gamesPlayed: 0,
        maxRound: 0,
        customStat: 0,
        gameName,
      } as T & { gameName: string });
};

const saveGameStats = (localStorageKey: string, stats: any) => {
  localStorage.setItem(localStorageKey, JSON.stringify(stats));
};

export const getClickGameStats = () =>
  getGameStats<any>('clickGameStats', 'ClickGame');

export const saveClickGameStats = (stats: any) => {
  saveGameStats('clickGameStats', stats);
};

export const getColorGameStats = () =>
  getGameStats<any>('colorGameStats', 'ColorGame');

export const saveColorGameStats = (stats: any) => {
  saveGameStats('colorGameStats', stats);
};

const games: Game[] = [
  {
    info: clickGameInfo,
    localStorageKey: 'clickGameStats',
    getStats: getClickGameStats,
    saveStats: saveClickGameStats, // <--- AÑADIDO
    component: ClickGame,
  },
  {
    info: twoGameInfo,
    localStorageKey: 'colorGameStats',
    getStats: getColorGameStats,
    saveStats: saveColorGameStats, // <--- AÑADIDO
    component: TwoGame,
  },
];

const sortedGames = [...games].sort((a, b) => a.info.id - b.info.id);

export const getGames = (): Game[] => sortedGames;

export const getGame = (id: number): Game | undefined =>
  sortedGames.find((game) => game.info.id === id);

export const getGameByPath = (path: string): Game | undefined =>
  sortedGames.find((game) => game.info.path === path);

export const getNextGame = (currentId: number): Game | undefined => {
  const currentIndex = sortedGames.findIndex(
    (game) => game.info.id === currentId
  );
  if (currentIndex === -1) return undefined;
  return sortedGames[(currentIndex + 1) % sortedGames.length];
};

export const getPreviousGame = (currentId: number): Game | undefined => {
  const currentIndex = sortedGames.findIndex(
    (game) => game.info.id === currentId
  );
  if (currentIndex === -1) return undefined;
  return sortedGames[
    (currentIndex - 1 + sortedGames.length) % sortedGames.length
  ];
};
