import React from 'react';
import { X } from 'lucide-react';
import BaseModal from './BaseModal';
import { motion } from 'framer-motion';
import { getGames } from '../games/registry';

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onClearSpecificCache: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onClearSpecificCache,
}) => {
  const handleConfirmLogout = () => {
    // Limpiar todas las estadísticas de los juegos
    const games = getGames();
    games.forEach((game) => {
      if (game.info.localStorageKey) {
        localStorage.removeItem(game.info.localStorageKey);
      }
    });

    // Limpiar datos del usuario
    const keysToRemove = ['accountCreationDate', 'userName', 'userId'];

    keysToRemove.forEach((key) => localStorage.removeItem(key));

    // Limpiar caché específico
    onClearSpecificCache();

    // Ejecutar logout
    onConfirm();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth="md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Tancar Sessió
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
      <div className="space-y-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>Estàs segur que vols tancar la sessió?</p>
      </div>
      <div className="mt-6 flex space-x-4">
        <motion.button
          onClick={handleConfirmLogout}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="flex-1 bg-[#ee5711] hover:bg-[#cc4709] text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-sm sm:text-base"
        >
          Tancar Sessió
        </motion.button>
        <motion.button
          onClick={onClose}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="flex-1 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-sm sm:text-base"
        >
          Cancel·lar
        </motion.button>
      </div>
    </BaseModal>
  );
};

export default LogoutModal;
