// InformationModal.tsx
import React, { useContext, useState, useEffect } from 'react';
import { X, RefreshCcw } from 'lucide-react';
import { motion } from 'framer-motion';
import BaseModal from './BaseModal';
import { ConfigContext } from '../context/ConfigContext';
import { useDonations } from '../context/DonationsContext';

interface InformationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InformationModal: React.FC<InformationModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { config, refreshConfig } = useContext(ConfigContext);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isUpdatingConfig, setIsUpdatingConfig] = useState<boolean>(false);

  useEffect(() => {
    // Sólo computamos el tiempo restante si el modal está abierto (o si quieres siempre, quita el check)
    if (!isOpen) return;

    let intervalId: number | undefined;

    const updateRemainingTime = () => {
      const lastFetchTsStr = localStorage.getItem('lastFetchTimestamp');
      const timeout = config.cacheTimeout;
      const now = Date.now();

      if (!lastFetchTsStr) {
        // Si no existe, asumimos que estamos en la primera carga
        // Mostrar "timeout" como tiempo restante:
        setTimeLeft(timeout);
        return;
      }

      const lastFetch = parseInt(lastFetchTsStr, 10);
      const elapsed = now - lastFetch;
      const remaining = Math.max(0, timeout - elapsed);
      setTimeLeft(remaining);

      // Cuando llega a 0, forzamos la actualización de la config
      if (remaining === 0 && !isUpdatingConfig) {
        setIsUpdatingConfig(true);
        refreshConfig().finally(() => setIsUpdatingConfig(false));
      }
    };

    // Llamamos una vez al montar y luego cada segundo
    updateRemainingTime();
    intervalId = window.setInterval(updateRemainingTime, 1000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpen, config.cacheTimeout, refreshConfig, isUpdatingConfig]);

  // Formateador de tiempo más detallado
  const formatTime = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    if (hours > 0) {
      return `${hours}h ${minutes.toString().padStart(2, '0')}m ${seconds
        .toString()
        .padStart(2, '0')}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
    } else {
      return `${seconds}s`;
    }
  };

  // Formateador de tiempo compacto
  const formatTimeShort = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth="md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Informació Important
        </h2>
        <div className="flex items-center gap-2">
          {/* Bloque de tiempo de refresco */}
          <span className="flex items-center gap-1.5 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white rounded-full px-3 py-2 text-sm sm:text-base">
            <RefreshCcw size={16} className="inline-block align-middle" />
            <span className="inline-block align-middle">
              <span className="hidden sm:inline">
                {timeLeft > 0 ? formatTime(timeLeft) : '0s'}
              </span>
              <span className="sm:hidden">
                {timeLeft > 0 ? formatTimeShort(timeLeft) : '0s'}
              </span>
            </span>
          </span>
          {/* Botón cerrar */}
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 w-5 h-5 flex items-center justify-center"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="space-y-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>
          Si canvies el teu nom mentre estàs al Top, no es mostrarà fins que
          facis una nova donació.
        </p>
        <p>
          Si fas una donació sense tenir un compte i el teu nom ja apareix al
          Top, serà substituït per <strong>"Anònim"</strong>.
        </p>
        <p>
          Si detectes que el teu nom apareix com <strong>"Anònim"</strong>,
          posa't en contacte amb nosaltres a través d'un missatge directe a{' '}
          <a
            href="https://x.com/messages/compose?recipient_id=1849015375087738880"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 inline-flex items-center"
            aria-label="Envía'ns un missatge a X (Twitter)"
          >
            <span className="inline-block">
              @minijocscat en
              <svg
                viewBox="0 0 24 24"
                className="w-2.5 h-2.5 xs:w-2.5 xs:h-2.5 sm:w-3.5 sm:h-3.5 fill-current ml-1 inline"
                aria-hidden="true"
              >
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </span>
          </a>
          .
        </p>
      </div>

      <motion.button
        onClick={onClose}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-sm sm:text-base"
      >
        Entès
      </motion.button>
    </BaseModal>
  );
};

export default InformationModal;
