import React from 'react';
import { X } from 'lucide-react';
import BaseModal from './BaseModal';
import { motion } from 'framer-motion';

interface UpdatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  changes: string[];
}

const UpdatesModal: React.FC<UpdatesModalProps> = ({
  isOpen,
  onClose,
  changes,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth="md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-white">
          Novetats
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <X size={24} />
        </button>
      </div>
      <div className="space-y-4 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>Aquesta versió inclou:</p>
        <ul className="list-disc pl-5 space-y-2">
          {changes.map((change, index) => (
            <li key={index}>{change}</li>
          ))}
        </ul>
        <p>Gràcies per ser part d'aquesta aventura!</p>
      </div>
      <motion.button
        onClick={onClose}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-base"
      >
        Entès
      </motion.button>
    </BaseModal>
  );
};

export default UpdatesModal;
