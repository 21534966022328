import React from 'react';
import { X } from 'lucide-react';
import BaseModal from './BaseModal';
import { motion } from 'framer-motion';

interface GooglePlayModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const GooglePlayModal: React.FC<GooglePlayModalProps> = ({
  isOpen,
  onClose,
}) => {
  const googlePlayLink =
    'https://play.google.com/store/apps/details?id=minijocs.app';

  // Función para manejar la descarga y establecer la bandera
  const handleDownloadClick = () => {
    // Establecer la bandera para no mostrar el modal nuevamente
    localStorage.setItem('googlePlayModalNeverShowAgain', 'true');
    // Cerrar el modal
    onClose();
    // Navegar a Google Play después de un pequeño retraso para asegurar que la bandera se establezca
    setTimeout(() => {
      window.open(googlePlayLink, '_blank', 'noopener,noreferrer');
    }, 100);
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth="md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Descarrega l'App!
        </h2>
        <button onClick={onClose} aria-label="Tancar modal">
          <X
            size={20}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          />
        </button>
      </div>
      <div className="space-y-3 text-sm sm:text-base text-gray-600 dark:text-gray-300">
        <p>
          Porta els teus minijocs preferits allà on vagis amb la nostra app per
          Android. Gaudeix d'una experiència optimitzada amb càrregues ràpides i
          controls adaptats per a mòbil.
        </p>
        <p>Amb l'app, tindràs accés a:</p>
        <ul className="list-disc list-inside mt-2">
          <li>Jugar sense connexió a internet.</li>
          <li>Descobriment de nous minijocs regularment.</li>
          <li>Accés ràpid i còmode als teus minijocs.</li>
          <li>Notificacions d'actualitzacions i novetats.</li>
        </ul>
        <p>
          Descarrega l'app i comença a gaudir ara mateix de tota la diversió!
        </p>
      </div>
      <motion.button
        onClick={handleDownloadClick}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-4 sm:mt-6 w-full bg-gradient-to-r from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 text-sm sm:text-base flex items-center justify-center"
        aria-label="Descarregar l'App a Google Play"
      >
        <span>Descarregar l'App a Google Play!</span>
      </motion.button>
    </BaseModal>
  );
};

export default GooglePlayModal;
