import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  User,
  Search,
  X,
  Dice6,
  Infinity as InfinityIcon,
  Volume2,
  Lock,
} from 'lucide-react';
import { TopDonations } from './components/TopDonations';
import DarkModeToggle from './components/DarkModeToggle';
import AdminButton from './components/AdminButton';
import Footer from './components/Footer';
import InstructionsModal from './components/InstructionsModal';
import UpdatesModal from './components/UpdatesModal';
import IdentityModal from './components/IdentityModal';
import { useAuth } from './context/AuthContext';
import { useTheme } from './context/ThemeContext';
import Logo from './components/Logo';
import { useSuppressWarnings } from './utils/suppressWarnings';
import { getGames } from './games/registry';
import { CURRENT_VERSION, VERSIONS } from './appVersions';
import {
  getGameTags,
  sortGames,
  GameTag,
  GameStat,
  Game,
} from './utils/gameUtils';
import GooglePlayModal from './components/GooglePlayModal';
import BackgroundTexture from './components/BackgroundTexture';
import { useSearchLogic } from './utils/searchUtils';
import {
  rollDice,
  diceIcons,
  DiceIcon,
  getRandomGame,
} from './utils/diceUtils';
import { checkAndClearCache } from './utils/cacheUtils';
import RickrollOverlay from './components/RickrollOverlay';
import CookieConsent from './components/CookieConsent';

const App: React.FC = () => {
  // Variable para activar/desactivar el video .mp4
  const SHOW_RICKROLL_VIDEO_OVERLAY = true;

  const [isLoaded, setIsLoaded] = useState(false);
  const [showRickroll, setShowRickroll] = useState(() => {
    return localStorage.getItem('hasPassedRickroll') !== 'true';
  });
  const [password, setPassword] = useState('');
  const correctPassword = 'a';
  const { isDarkMode } = useTheme();
  const { user, login } = useAuth();
  const donationsRef = useRef<HTMLDivElement | null>(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showUpdates, setShowUpdates] = useState(false);
  const [showIdentity, setShowIdentity] = useState(false);
  const [currentChanges, setCurrentChanges] = useState<string[]>([]);
  const [currentVersion, setCurrentVersion] = useState<string>('');
  const [showGooglePlayModal, setShowGooglePlayModal] = useState(false);
  const [showUserPage, setShowUserPage] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const games = getGames();
  useSuppressWarnings();
  const [gameStats, setGameStats] = useState<GameStat[]>([]);
  const [hoveredGameId, setHoveredGameId] = useState<string | null>(null);
  const headerRef = useRef<HTMLElement>(null);
  const userButtonRef = useRef<HTMLButtonElement>(null);
  const leftButtonsRef = useRef<HTMLDivElement>(null);
  const rightButtonsRef = useRef<HTMLDivElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const RandomButtonRef = useRef<HTMLButtonElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const [expandedGameId, setExpandedGameId] = useState<string | null>(null);
  const [cardHeights, setCardHeights] = useState<{ [key: string]: number }>({});
  const cardRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [diceRoll, setDiceRoll] = useState<number | null>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [inputWidth, setInputWidth] = useState(100);
  const [searchBarWidth, setSearchBarWidth] = useState(0);
  const [searchHeight, setSearchHeight] = useState(0);
  const MIN_INPUT_WIDTH = 100;
  const MAX_INPUT_WIDTH = 300;
  const [isSearchIconRotating, setIsSearchIconRotating] = useState(false);
  const [iconState, setIconState] = useState<'search' | 'x'>('search');
  const [placeholdersHidden, setPlaceholdersHidden] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchPlaceholder, setSearchPlaceholder] = useState<string>(
    'Busca per noms, descripcions o etiquetes'
  );

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

  const [isMuted, setIsMuted] = useState(true);

  const [justShowedInstructions, setJustShowedInstructions] = useState(false);

  useEffect(() => {
    const updateSearchPlaceholder = () => {
      if (headerRef.current && searchButtonRef.current) {
        const availableWidth =
          headerRef.current.offsetWidth -
          (leftButtonsRef.current?.offsetWidth || 0) -
          (rightButtonsRef.current?.offsetWidth || 0) -
          (searchButtonRef.current.offsetWidth || 0) -
          100;

        let newPlaceholder = '';
        if (availableWidth >= 600) {
          newPlaceholder =
            'Busca per noms, descripcions, categories o etiquetes';
        } else if (availableWidth >= 450) {
          newPlaceholder = 'Busca per noms, descripcions o categories';
        } else if (availableWidth >= 300) {
          newPlaceholder = 'Busca per noms o descripcions';
        } else if (availableWidth >= 100) {
          newPlaceholder = 'Busca per noms';
        } else {
          newPlaceholder = 'Busca';
        }
        setSearchPlaceholder(newPlaceholder);
      }
    };

    updateSearchPlaceholder();
    window.addEventListener('resize', updateSearchPlaceholder);

    return () => window.removeEventListener('resize', updateSearchPlaceholder);
  }, []);

  const statsMemo = useMemo(() => {
    return gameStats
      .map((stat) => `${stat.id}-${stat.gamesPlayed}-${stat.maxRound}`)
      .join(',');
  }, [gameStats]);

  useEffect(() => {
    const stats = games.map((game) => {
      const gameStats = game.getStats();
      return {
        id: game.info.id,
        gamesPlayed: gameStats?.gamesPlayed || 0,
        maxRound: gameStats?.maxRound || 0,
      };
    });
    setGameStats(stats);
  }, [games]);

  const mostPlayedGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) =>
        current.gamesPlayed > prev.gamesPlayed ? current : prev,
      { id: 0, gamesPlayed: 0, maxRound: 0 }
    );
  }, [gameStats]);

  const bestRoundGame = useMemo(() => {
    return gameStats.reduce(
      (prev, current) => (current.maxRound > prev.maxRound ? current : prev),
      { id: 0, gamesPlayed: 0, maxRound: 0 }
    );
  }, [gameStats]);

  const orderedGames = useMemo(() => {
    return sortGames(games, gameStats, mostPlayedGame.id, bestRoundGame.id);
  }, [games, gameStats, mostPlayedGame.id, bestRoundGame.id]);

  const {
    filteredGames,
    hiddenGames,
    placeholdersHidden: searchPlaceholdersHidden,
  } = useSearchLogic({
    searchValue,
    gameStats,
    mostPlayedGameId: mostPlayedGame.id,
    bestRoundGameId: bestRoundGame.id,
    orderedGames,
  });
  useEffect(() => {
    setPlaceholdersHidden(searchPlaceholdersHidden);
  }, [searchPlaceholdersHidden]);

  const hasAnyGameBeenPlayed = () => {
    return gameStats.some((game) => game.gamesPlayed > 0);
  };

  const pageLoadCountRef = useRef(0);
  const hasIncrementedRef = useRef(false);

  useEffect(() => {
    const calculateSearchBarWidth = () => {
      if (headerRef.current && searchButtonRef.current) {
        const headerWidth = headerRef.current.offsetWidth;
        const searchButtonWidth = searchButtonRef.current.offsetWidth || 0;

        const maxWidthPercentage = isSmallScreen ? 0.9 : 0.7;
        const minWidthPercentage = 0.3;
        const minWidthFixed = 100;

        const maxWidth = headerWidth * maxWidthPercentage;
        const minWidth =
          headerWidth * minWidthPercentage > minWidthFixed
            ? headerWidth * minWidthPercentage
            : minWidthFixed;

        let calculatedWidth = Math.min(
          maxWidth,
          Math.max(minWidth, headerWidth * 0.5)
        );

        setSearchBarWidth(calculatedWidth);
        setSearchHeight(searchButtonRef.current.offsetHeight);
      }
    };

    calculateSearchBarWidth();
    window.addEventListener('resize', calculateSearchBarWidth);

    return () => window.removeEventListener('resize', calculateSearchBarWidth);
  }, [isSmallScreen]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node) &&
        searchButtonRef.current &&
        !searchButtonRef.current.contains(event.target as Node)
      ) {
        if (isSearchOpen) {
          setIsSearchIconRotating(true);
          setTimeout(() => {
            setIsSearchIconRotating(false);
            setIconState('search');
          }, 300);
          closeSearch();
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isSearchOpen]);

  useEffect(() => {
    const loadTimeout = setTimeout(() => setIsLoaded(true), 100);
    window.scrollTo(0, 0);

    const initializeApp = async () => {
      // Primero verificamos y limpiamos la caché si es necesario
      const cacheWasCleared = await checkAndClearCache();

      // Si el caché fue limpiado, no continuamos porque la página se recargará
      if (cacheWasCleared) return;

      // Verificar si es primera visita y versión actual
      const lastSeenVersion = localStorage.getItem('lastSeenVersion');
      const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

      // Solo mostrar actualizaciones si no es la primera visita
      if (hasVisitedBefore && lastSeenVersion !== CURRENT_VERSION) {
        // Buscar los cambios de la versión actual
        const currentVersionData = VERSIONS.find(
          (v) => v.version === CURRENT_VERSION
        );
        if (currentVersionData) {
          setCurrentChanges(currentVersionData.changes);
          setCurrentVersion(CURRENT_VERSION);
          setShowUpdates(true);
        }
      }

      // Marcar que ya ha visitado la web
      if (!hasVisitedBefore) {
        localStorage.setItem('hasVisitedBefore', 'true');
        localStorage.setItem('lastSeenVersion', CURRENT_VERSION);
      }

      // Verificar si ya se han visto las instrucciones
      const haVistoInstruccions = localStorage.getItem('haVistoInstruccions');

      // Si no ha visto las instrucciones, las mostramos
      if (haVistoInstruccions !== 'true') {
        setShowInstructions(true);
        localStorage.setItem('haVistoInstruccions', 'true');
      }

      const userName = localStorage.getItem('userName');
      if (userName) {
        setShowUserPage(true);
      }

      const openIdentityModalParam = searchParams.get('ObrirModalUsuari');
      if (openIdentityModalParam === 'true') {
        setShowIdentity(true);
      }
    };

    initializeApp();

    const storedPageLoadCount = localStorage.getItem('pageLoadCount');
    pageLoadCountRef.current = storedPageLoadCount
      ? parseInt(storedPageLoadCount, 10)
      : 0;

    const handleBeforeUnload = () => {
      localStorage.removeItem('hasVisitedBefore');
      localStorage.removeItem('lastSeenVersion');
      localStorage.removeItem('pageLoadCount');
      localStorage.removeItem('googlePlayModalNeverShowAgain');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearTimeout(loadTimeout);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [searchParams]);

  useEffect(() => {
    if (!hasIncrementedRef.current) {
      pageLoadCountRef.current += 1;
      localStorage.setItem(
        'pageLoadCount',
        pageLoadCountRef.current.toString()
      );

      const neverShowAgain =
        localStorage.getItem('googlePlayModalNeverShowAgain') === 'true';
      const isApp =
        new URLSearchParams(window.location.search).get('app') === 'true';

      if (
        !isApp &&
        !neverShowAgain &&
        pageLoadCountRef.current % 999 === 0 &&
        !showInstructions &&
        !showUpdates &&
        !showIdentity
      ) {
        setShowGooglePlayModal(true);
      }

      hasIncrementedRef.current = true;
    }
  }, [showInstructions, showUpdates, showIdentity]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key && event.key.startsWith('gameStats')) {
        const updatedStats = games.map((game) => {
          const gameStats = game.getStats();
          return {
            id: game.info.id,
            gamesPlayed: gameStats?.gamesPlayed || 0,
            maxRound: gameStats?.maxRound || 0,
          };
        });
        setGameStats(updatedStats);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [games]);

  const getColumnCount = () => {
    if (window.innerWidth >= 1024) return 3;
    if (window.innerWidth >= 768) return 2;
    return 1;
  };

  const [columnCount, setColumnCount] = useState(getColumnCount());

  useEffect(() => {
    const handleResize = () => {
      setColumnCount(getColumnCount());
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderPlaceholderCards = () => {
    if (
      searchValue ||
      placeholdersHidden ||
      filteredGames.length > 5 ||
      isSearchOpen
    )
      return null;

    const placeholdersNeeded =
      Math.ceil(filteredGames.length / columnCount) * columnCount -
      filteredGames.length;

    const placeholderClass =
      'text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 dark:text-white truncate group-hover:text-yellow-500';

    return Array.from({ length: placeholdersNeeded }).map((_, index) => (
      <div
        key={`placeholder-${index}`}
        className="group bg-white dark:bg-gray-800 shadow-md rounded-lg flex items-center justify-center h-full transform transition-transform hover:scale-105"
      >
        <span className={placeholderClass}>Pròximament</span>
      </div>
    ));
  };

  const hasAnyGameBeenPlayedFlag = hasAnyGameBeenPlayed();

  const gameTitleClasses =
    'text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 dark:text-white transition-colors duration-300';

  const handleOpenRandomGame = () => {
    if (games.length > 0) {
      setIsLoading(true);
      const randomGameData = getRandomGame(games);

      if (randomGameData) {
        const { game, diceRoll } = randomGameData;
        setDiceRoll(diceRoll);
        setTimeout(() => {
          setIsLoading(false);
          setDiceRoll(null);
          navigate(game.info.path);
        }, 1000);
      } else {
        setIsLoading(false);
        setDiceRoll(null);
        console.error(
          'Could not find a random game. Please check configuration.'
        );
      }
    }
  };

  const handleSearchButtonClick = () => {
    setIsSearchIconRotating(true);
    if (isSearchOpen) {
      setTimeout(() => {
        setIsSearchIconRotating(false);
        setIconState('search');
      }, 300);
      closeSearch();
    } else {
      setIsSearchOpen(true);
      setPlaceholdersHidden(true);
      setTimeout(() => {
        setIsSearchIconRotating(false);
        setIconState('x');
        searchInputRef.current?.focus();
      }, 300);
    }
  };

  const handleMouseEnter = (gameId: string) => {
    setExpandedGameId(gameId);
  };

  const handleMouseLeave = () => {
    setExpandedGameId(null);
  };

  useEffect(() => {
    const updateCardHeights = () => {
      if (cardRefs.current) {
        const newCardHeights: { [key: string]: number } = {};
        for (const gameId in cardRefs.current) {
          const cardElement = cardRefs.current[gameId];
          if (cardElement) {
            newCardHeights[gameId] = cardElement.offsetHeight;
          }
        }
        setCardHeights(newCardHeights);
      }
    };
    updateCardHeights();
    window.addEventListener('resize', updateCardHeights);

    return () => window.removeEventListener('resize', updateCardHeights);
  }, [filteredGames, expandedGameId]);

  const getTagStyle = (isDarkMode: boolean) => {
    return {
      backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF',
      color: isDarkMode ? '#FFFFFF' : '#1F2937',
    };
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 20) {
      setSearchValue(value);
      const newWidth = Math.min(
        Math.max(value.length * 10 + 60, MIN_INPUT_WIDTH),
        MAX_INPUT_WIDTH
      );
      setInputWidth(newWidth);
    }
  };
  const closeSearch = () => {
    setIsSearchOpen(false);
    setSearchValue('');
    setInputWidth(MIN_INPUT_WIDTH);
    setPlaceholdersHidden(false);
  };

  const renderUpdatesModal = () => {
    return (
      <UpdatesModal
        isOpen={showUpdates}
        onClose={() => {
          // Primero guardamos la versión
          localStorage.setItem(`lastSeenVersion`, CURRENT_VERSION);
          // Esperamos a que termine la animación de salida (300ms)
          setTimeout(() => {
            setShowUpdates(false);
          }, 300);
        }}
        version={currentVersion}
        changes={currentChanges}
      />
    );
  };

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === correctPassword) {
      setShowRickroll(false);
      localStorage.setItem('hasPassedRickroll', 'true');
    }
  };

  const handleVideoCanPlay = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = e.currentTarget;
    video.play();
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleRickrollPassed = () => {
    setShowRickroll(false);
    localStorage.setItem('hasPassedRickroll', 'true');
  };

  return (
    <div className="relative z-0">
      {/* Condicional para el video overlay */}
      {SHOW_RICKROLL_VIDEO_OVERLAY && (
        <RickrollOverlay
          showRickroll={showRickroll}
          onPasswordCorrect={handleRickrollPassed}
        />
      )}
      <BackgroundTexture className="background-texture" zIndex={-1} />
      <div className="flex flex-col min-h-screen bg-texture transition-colors duration-300">
        <div className="flex-grow px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
          <div className="max-w-7xl mx-auto">
            <header
              className={`flex justify-between items-center mb-6 sm:mb-8 transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : '-translate-y-10 opacity-0'
              } relative`}
              ref={headerRef}
            >
              <div
                className={`flex items-center space-x-2 transition-all duration-300 z-`}
                ref={leftButtonsRef}
              >
                {showUserPage ? (
                  <button
                    ref={userButtonRef}
                    onClick={() => navigate('/usuari')}
                    className="group focus-visible:outline-none"
                  >
                    <div className="icon-bg">
                      <User className="text-white w-1/2 h-1/2" />
                    </div>
                  </button>
                ) : (
                  <button
                    ref={userButtonRef}
                    onClick={() => setShowIdentity(true)}
                    className="group focus-visible:outline-none"
                  >
                    <div className="icon-bg">
                      <User className="text-white w-1/2 h-1/2" />
                    </div>
                  </button>
                )}
                <button
                  ref={buttonRef}
                  className={`group relative focus-visible:outline-none transition-all duration-300 ${
                    isLoading ? 'button-loading' : ''
                  }`}
                  onClick={handleOpenRandomGame}
                  disabled={isLoading}
                  style={{ zIndex: 30 }}
                >
                  <div className="icon-bg">
                    {isLoading && diceRoll !== null ? (
                      <div className="dice-container">
                        {React.createElement(diceIcons[diceRoll], {
                          className: 'text-white w-1/2 h-1/2 dice-animation',
                        })}
                      </div>
                    ) : (
                      <Dice6 className="text-white w-1/2 h-1/2" />
                    )}
                  </div>
                </button>
              </div>
              <div
                className={`flex items-center space-x-2 transition-all duration-300 ${
                  isSearchOpen
                    ? 'opacity-0 pointer-events-none transform scale-0'
                    : 'opacity-100 transform scale-100'
                }`}
              >
                <Logo className="w-10 h-10 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:w-16 lg:h-16" />

                {!isSmallScreen && (
                  <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-red-500 leading-tight">
                    minijocs.cat
                  </h1>
                )}
              </div>
              <div
                ref={searchContainerRef}
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center transition-all duration-300 overflow-hidden ${
                  isSearchOpen
                    ? 'opacity-100 scale-100'
                    : 'opacity-0 pointer-events-none scale-0'
                }`}
                style={{
                  width: `${searchBarWidth}px`,
                  height: `${searchHeight}px`,
                  transition: 'opacity 0.3s, transform 0.3s',
                  zIndex: 10,
                }}
              >
                <div
                  ref={searchRef}
                  className="flex-1 flex items-center h-full"
                  style={{ width: `${inputWidth}px` }}
                >
                  <input
                    type="text"
                    placeholder={searchPlaceholder}
                    className="px-4 py-2 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-white flex-1 mr-2 focus:outline-none h-full text-center"
                    value={searchValue}
                    onChange={handleSearchInputChange}
                    ref={searchInputRef}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div
                className="flex items-center space-x-2"
                ref={rightButtonsRef}
                style={{ zIndex: 20 }}
              >
                <AdminButton />
                <button
                  ref={searchButtonRef}
                  onClick={handleSearchButtonClick}
                  className="icon-bg group relative focus-visible:outline-none"
                  style={{ zIndex: 30 }}
                >
                  <div className="relative w-1/2 h-1/2">
                    <Search
                      className={`absolute inset-0 h-full w-full transition-all duration-500 ease-in-out transform ${
                        isSearchOpen
                          ? 'opacity-0 rotate-90 scale-50'
                          : 'opacity-100 rotate-0 scale-100'
                      } text-white`}
                    />
                    <X
                      className={`absolute inset-0 h-full w-full transition-all duration-500 ease-in-out transform ${
                        isSearchOpen
                          ? 'opacity-100 rotate-0 scale-100'
                          : 'opacity-0 -rotate-90 scale-50'
                      } text-white`}
                    />
                  </div>
                </button>
                <DarkModeToggle />
              </div>
            </header>
            <main
              className={`mt-4 sm:mt-6 md:mt-8 transition-all duration-300 ${
                isLoaded
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              }`}
            >
              {filteredGames.length > 0 ? (
                <div
                  className={`grid gap-4 sm:gap-6 md:gap-8 ${
                    columnCount === 1
                      ? 'grid-cols-1'
                      : columnCount === 2
                      ? 'grid-cols-2'
                      : 'grid-cols-3'
                  }`}
                >
                  {filteredGames.map((game, index) => {
                    const tags: GameTag[] = getGameTags(
                      game.info.id,
                      gameStats,
                      mostPlayedGame.id,
                      bestRoundGame.id
                    );
                    const isGameHidden = hiddenGames.includes(game.info.id);

                    return (
                      <Link
                        key={game.info.id}
                        to={`${game.info.path}`}
                        className={`group block game-card-container transition-opacity duration-300 ${
                          isGameHidden
                            ? 'opacity-0 pointer-events-none transform scale-0'
                            : 'opacity-100 transform scale-100'
                        }`}
                        onMouseEnter={() => setHoveredGameId(game.info.id)}
                        onMouseLeave={() => setHoveredGameId(null)}
                        style={{
                          animationDelay: `${index * 0.05}s`,
                          animationName: 'fadeIn',
                          animationDuration: '0.3s',
                          animationTimingFunction: 'ease-out',
                          animationFillMode: 'both',
                        }}
                      >
                        <div
                          className={`relative bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden h-full game-card transform transition-transform duration-300 ${
                            hoveredGameId === game.info.id
                              ? 'raised-game-card'
                              : 'group-hover:raised-game-card'
                          }`}
                        >
                          <div className="absolute top-2 left-2 flex z-20">
                            {game.info.category && (
                              <div
                                style={{
                                  backgroundColor: isDarkMode
                                    ? '#1F2937'
                                    : '#FFFFFF',
                                  color: isDarkMode ? '#FFFFFF' : '#1F2937',
                                }}
                                className="text-xs sm:text-sm px-2 sm:px-3 py-0.5 sm:py-1 rounded-md font-medium whitespace-nowrap flex items-center justify-center"
                              >
                                {game.info.category}
                              </div>
                            )}
                          </div>

                          <div className="h-24 sm:h-32 md:h-40 bg-gradient-to-r from-yellow-500  to-red-500 flex items-center justify-center relative overflow-hidden">
                            <game.info.icon className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 text-white relative z-10 transform group-hover:scale-110 transition-transform duration-300" />
                          </div>
                          <div className="p-3 sm:p-4 flex items-center justify-between">
                            <h3
                              className={`text-lg sm:text-xl md:text-2xl font-semibold transition-colors duration-300 ${
                                hoveredGameId === game.info.id
                                  ? 'text-yellow-500'
                                  : 'text-gray-800 dark:text-white group-hover:text-yellow-500'
                              }`}
                            >
                              {game.info.title}
                            </h3>
                            <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 text-right self-center -translate-y-1">
                              {game.info.description}
                            </p>
                          </div>

                          <div className="absolute top-2 right-2 flex z-20">
                            {tags
                              .sort((a, b) => b.priority - a.priority)
                              .map((tag, index) => (
                                <div
                                  key={index}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? '#1F2937'
                                      : '#FFFFFF',
                                    color: isDarkMode ? '#FFFFFF' : '#1F2937',
                                  }}
                                  className="text-xs sm:text-sm px-2 sm:px-3 py-0.5 sm:py-1 rounded-md ml-1 font-medium whitespace-nowrap"
                                >
                                  {tag.label}
                                </div>
                              ))}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                  {renderPlaceholderCards()}
                </div>
              ) : (
                <div className="text-center mt-8">
                  <p className="text-gray-600 dark:text-gray-400">
                    {searchValue
                      ? "No s'han trobat minijocs que coincideixin amb la teva cerca."
                      : 'No hi ha minijocs disponibles.'}
                  </p>
                </div>
              )}

              <div ref={donationsRef} id="donations" className="mt-8">
                <Link to="/donar">
                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-xl group">
                    <div className="p-3 sm:p-4">
                      <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-gray-800 dark:text-white transition-colors duration-300 flex items-center justify-center group-hover:text-yellow-500">
                        Top Donadors
                      </h2>
                      <TopDonations limit={3} />
                    </div>
                  </div>
                </Link>
              </div>
            </main>
          </div>
        </div>
        <Footer />
        <InstructionsModal
          isOpen={showInstructions}
          onClose={() => {
            setShowInstructions(false);
          }}
        />
        {!showInstructions && renderUpdatesModal()}
        <IdentityModal
          isOpen={showIdentity}
          onClose={() => {
            setShowIdentity(false);
          }}
          userName={localStorage.getItem('userName') || ''}
        />
        <GooglePlayModal
          isOpen={showGooglePlayModal}
          onClose={() => {
            setShowGooglePlayModal(false);
          }}
        />
        <div className="absolute bottom-0 right-0 p-2">
          <button
            id="hidden-netlify-identity"
            onClick={login}
            className="w-2 h-2 opacity-0 hover"
          ></button>
        </div>
      </div>
      <CookieConsent />
    </div>
  );
};

export default App;
